// Core
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';

//RXJS
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

// Ngx Bootstrap
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  BsDatepickerConfig,
  BsDatepickerDirective,
} from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import {
  itLocale,
  enGbLocale,
  esLocale,
  frLocale,
  plLocale,
  ptBrLocale,
  deLocale,
  ruLocale,
  nlLocale,
  arLocale,
} from 'ngx-bootstrap/locale';

// Dialogs
import { EmployeeDialogComponent } from 'app/shared/employee-dialog/employee-dialog.component';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { DeferDialogComponent } from 'app/shared/defer-dialog/defer-dialog.component';
import { CloneCycleDialogComponent } from 'app/shared/clone-cycle-dialog/clone-cycle-dialog.component';
import { ImageSelectorComponent } from './image-selector/image-selector.component';

// Services
import { CoreService } from 'app/shared/services/core.service';
import { companyService } from 'app/super-admin/company/company.service';
import { CompanyReportService } from '../../../company-report.service';
import { CyclesUtilitiesService } from 'app/shared/services/cycles-utilities.service';
import { TranslateService } from '@ngx-translate/core';
import { CycleManagerService } from 'app/components/common/cycle-manager/cycle-manager.service';

// Utility
import * as moment from 'moment';
import { DndDropEvent } from 'ngx-drag-drop';
import { environment } from '../../../../../environments/environment';
import { thresholdFreedmanDiaconis, thresholdSturges } from 'd3';
import { Item } from 'angular2-multiselect-dropdown';
import { SharedService } from 'app/shared/services/shared.service';
import { ManagerOkrPopupComponent } from 'app/shared/components/manager-okr-popup/manager-okr-popup.component';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { DomSanitizer } from '@angular/platform-browser';
import { base64ToFile } from 'ngx-image-cropper';
import { CustomImageCropperComponent } from 'app/shared/components/custom-image-cropper/custom-image-cropper.component';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';

export enum REFERRAL_ACTION {
  ASSIGN = 0,
  VIEW = 1,
}

export enum REFERRAL {
  CYCLES = 0,
  MY_OBJECTIVES = 1,
  MY_STORY = 2,
}

export enum VIEW_MODE {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  INDIVIDUAL = 'INDIVIDUAL',
  MY_STORY = 'MY_STORY',
}

export enum VIEW_TYPE {
  HIDDEN = 'HIDDEN',
  VIEW_ONLY = 'VIEW_ONLY',
  EDIT = 'EDIT',
  ICONS = 'ICONS',
}

@Component({
  selector: 'app-obj-sheet-popup',
  templateUrl: './obj-sheet-popup.component.html',
  styleUrls: [
    './obj-sheet-popup.component.scss',
    '../../../../magic-checkbox.scss',
    '../../../../common_styles.scss',
  ],
})
export class ObjSheetPopupComponent implements OnInit {
  @Output() updateObjectiveInSheets = new EventEmitter<any>();
  @Output() removeObjectiveInSheets = new EventEmitter<any>();
  @Output() assignObjective = new EventEmitter<any>();
  public onClose: Subject<any>;
  content: any;
  objective: any;
  originalObjective: any;
  currentState: string;
  currentCycle: any;
  maxScale: number = 5;
  NoDescriptionText: string = '-';
  dropDownVisible: false;
  isHeader: boolean = true;
  isManager: boolean = false;
  olddata: any;

  inDefinitionPhase: boolean = false;
  afterDefinitionPhase: boolean = false;
  entroAgreeDate: boolean = false;
  fromExecutionPhase: boolean = false;
  inEvaluationPhase: boolean = false;
  inExecutionPhase: boolean = false;
  beforeCycleExecutionEnd: boolean = false;
  beforeEvaluationEnd: boolean = false;
  afterEvaluation: boolean = false;

  subscriptionOnHidden: Subscription;

  showCloneOption: boolean;

  ViewMode: VIEW_MODE = VIEW_MODE.INDIVIDUAL; //INDIVIDUAL, MANAGER, ADMIN
  referral: REFERRAL = REFERRAL.MY_OBJECTIVES; // Da dove viene richiamato
  referralAction: REFERRAL_ACTION = REFERRAL_ACTION.VIEW; // Da quale pulsante viene richiamato

  // TABS
  // Networks
  upLinksModelChanged: Subject<any> = new Subject<any>();

  minmaxChange: Subject<any> = new Subject<any>();
  isSaveObjective: boolean;

  public get viewMode(): typeof VIEW_MODE {
    return VIEW_MODE;
  }

  public get viewType(): typeof VIEW_TYPE {
    return VIEW_TYPE;
  }

  public get refAction(): typeof REFERRAL_ACTION {
    return REFERRAL_ACTION;
  }

  visibilityList: any = [];
  allVisibilityList: any = [];
  objectiveStateList: any = {
    DEFINITION_STATE: {
      field: 'DefinitionState',
      values: ['Open', 'AwaitingApproval', 'Set'],
    },
    EXECUTION_STATE: {
      field: 'ProgressState',
      values: ['ToDo', 'WorkingOn', 'Completed'],
    },
    EVALUATION_STATE: {
      field: 'EvaluationState',
      values: ['NotEvaluated', 'Evaluated', 'Deferred'],
    },
  };
  objectiveState: string;

  amsd_settings_categories: any;
  amsd_settings_visibility: any;

  selectedVisibility: any;
  selectedCategory: any;

  userInfo: any;
  enableAddChecklistItem: boolean = false;
  eval_arr: any = {};
  eval_arr_Inclination: any = {};
  eval_arr_Challenge: any = {};

  ImNotAuthor: boolean = false;
  ImAuthor: boolean = false;
  ImOwner: boolean = false;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  croppedImage: any = '';
  finalCroppedImage: any;
  optionalPhotoData: any;
  isUploading: boolean = false;
  newPatData: any = {};
  ischangeImg: boolean = false;
  currentFile: any;
  finalName: any;

  private newCheckItem: ElementRef;
  @ViewChild('newCheckItem') set _newCheckItem(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.newCheckItem = content;
    }
  }

  private addActivity: ElementRef;
  @ViewChild('addActivity') set _addActivity(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.addActivity = content;
    }
  }

  cat: any;

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  publicCaption: string;
  privateCaption: string;
  limitedCaption: string;
  reducedCaption: string;

  a2mSettingsNetworks: any;
  a2mSettingsNetworksLoading: boolean = false;
  showOkrSettingsEdit = false;
  objectiveDueDate;
  showTranslate: boolean = false;
  isEditableImg: boolean = false;
  isMobile: boolean;

  @ViewChild(BsDatepickerDirective, { static: false })
  datepicker?: BsDatepickerDirective;

  constructor(
    private coreService: CoreService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private companyReportService: CompanyReportService,
    private ref: ChangeDetectorRef,
    private srv: companyService,
    public cyclesUtilitiesService: CyclesUtilitiesService,
    private translateService: TranslateService,
    private cycleManagerService: CycleManagerService,
    private sharedService: SharedService,
    private pp360SpinnerService: Pp360SpinnerService,
    private sanitizer: DomSanitizer,
    public screenSizeService: ScreenSizeService
  ) {
    this.NoDescriptionText = coreService.getTranslation(
      'Generic.NoDescription'
    );

    this.showCloneOption = environment.showClone;

    this.publicCaption = this.coreService.getTranslation(
      'CYCLE_MANAGER.Public'
    );
    this.limitedCaption = this.coreService.getTranslation(
      'CYCLE_MANAGER.Limited'
    );
    this.reducedCaption = this.coreService.getTranslation(
      'CYCLE_MANAGER.Reduced'
    );
    this.privateCaption = this.coreService.getTranslation(
      'CYCLE_MANAGER.Private'
    );
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }

  ngOnDestroy() {
    // console.log('ngOnDestroy');
    if (this.subscriptionOnHidden) this.subscriptionOnHidden.unsubscribe();
  }

  cycles: any;
  ngOnInit() {
    this.userInfo = this.coreService.getUserInfo();

    this.visibilityList = [
      {
        id: '1',
        itemName: this.publicCaption,
        Value: 'Public',
        Hint: this.coreService.getTranslation(
          'OBJECTIVE_SHEET_POPUP.VisibilityPublicHint'
        ),
        Icon: 'fa fa-share-alt',
      },
      {
        id: '4',
        itemName: this.reducedCaption,
        Value: 'Reduced',
        Hint: this.coreService.getTranslation(
          'OBJECTIVE_SHEET_POPUP.VisibilityReducedHint'
        ),
        Icon: 'fa fa-user-circle-o',
      },
      {
        id: '2',
        itemName: this.limitedCaption,
        Value: 'Limited',
        Hint: this.coreService.getTranslation(
          'OBJECTIVE_SHEET_POPUP.VisibilityLimitedHint'
        ),
        Icon: 'fa fa-address-book-o',
      },
    ];
    this.onClose = new Subject();

    this.userInfo.EvaluationScale.Values.forEach((element: any) => {
      let trans = element.Translations.find(
        (x) => x.LanguageCode === this.userInfo.language
      );
      let desc = element.Description;
      let label = element.Name;
      if (trans) {
        desc = trans.Description;
        label = trans.Name;
      }
      this.eval_arr[element.Value] = { label: label, description: desc };
      if (element.Value > this.maxScale) this.maxScale = element.Value;
    });
    for (let i = 1; i <= 5; i++) {
      this.eval_arr_Inclination[i] = {
        label: this.coreService.getTranslation('LabelsScales.Inclination.' + i),
      };
      this.eval_arr_Challenge[i] = {
        label: this.coreService.getTranslation('LabelsScales.Challenge.' + i),
      };
    }

    this.allVisibilityList = [
      {
        id: '3',
        itemName: this.privateCaption,
        Value: 'Private',
        Hint: this.coreService.getTranslation(
          'OBJECTIVE_SHEET_POPUP.VisibilityPrivateHint'
        ),
        Icon: 'fa fa-eye-slash',
      },
      {
        id: '1',
        itemName: this.publicCaption,
        Value: 'Public',
        Hint: this.coreService.getTranslation(
          'OBJECTIVE_SHEET_POPUP.VisibilityPublicHint'
        ),
        Icon: 'fa fa-share-alt',
      },
      {
        id: '4',
        itemName: this.reducedCaption,
        Value: 'Reduced',
        Hint: this.coreService.getTranslation(
          'OBJECTIVE_SHEET_POPUP.VisibilityReducedHint'
        ),
        Icon: 'fa fa-user-circle-o',
      },
      {
        id: '2',
        itemName: this.limitedCaption,
        Value: 'Limited',
        Hint: this.coreService.getTranslation(
          'OBJECTIVE_SHEET_POPUP.VisibilityLimitedHint'
        ),
        Icon: 'fa fa-address-book-o',
      },
    ];

    // console.log('visibilityList', this.visibilityList);
    // console.log('allVisibilityList', this.allVisibilityList);

    moment.locale(this.userInfo.language);
    switch (this.userInfo.language) {
      case 'en':
        defineLocale(this.userInfo.language, enGbLocale);
        break;
      case 'it':
        defineLocale(this.userInfo.language, itLocale);
        break;
      case 'pt':
        defineLocale(this.userInfo.language, ptBrLocale);
        break;
      case 'es':
        defineLocale(this.userInfo.language, esLocale);
        break;
      case 'fr':
        defineLocale(this.userInfo.language, frLocale);
        break;
      case 'pl':
        defineLocale(this.userInfo.language, plLocale);
        break;
      case 'de':
        defineLocale(this.userInfo.language, deLocale);
        break;
      case 'ru':
        defineLocale(this.userInfo.language, ruLocale);
        break;
      case 'nl':
        defineLocale(this.userInfo.language, nlLocale);
        break;
      case 'ar':
        defineLocale(this.userInfo.language, arLocale);
        break;
    }

    if (this.ViewMode !== VIEW_MODE.INDIVIDUAL) {
      this.pp360SpinnerService.showSpinner(true);
      this.srv.getCompanyCycles(true).subscribe((result: any) => {
        // console.log('loadCycles', result);
        this.cycles = result;
        this.pp360SpinnerService.showSpinner(false);
      });
    }

    // TABS
    // Networks

    this.upLinksModelChanged
      .pipe(
        debounceTime(700), // wait 300ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe((filter) => {
        console.log('modelChanged', filter);
        this.currentUpLinksPageStart = 1;
        this.currentUpLinksFilterText = filter;

        this.getUpLinks(
          this.currentUpLinksPageStart,
          this.upLinksPageSize,
          this.currentUpLinksFilterText,
          true
        );
      });

    this.minmaxChange
      .pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
      .subscribe((ckItem: any) => {
        if (ckItem.MinValue > ckItem.Value) {
          ckItem.Value = ckItem.MinValue;
        } else if (ckItem.TargetValue < ckItem.Value) {
          ckItem.Value = ckItem.TargetValue;
        }
      });
  }

  getdpConfig() {
    this.dpConfig.dateInputFormat =
      this.userInfo.language === 'it' ? 'D MMM, YYYY' : 'MMM D, YYYY';
    this.dpConfig.containerClass = 'magda_datepicker_theme';
    this.dpConfig.showWeekNumbers = false;
    this.dpConfig.customTodayClass = 'custom-today-class';
    return this.dpConfig;
  }

  canRemoveCheckListItem(item) {
    let canRemove: boolean = false;
    switch (this.ViewMode) {
      case VIEW_MODE.INDIVIDUAL:
        canRemove =
          (item.Author.Id === this.userInfo.linkedEmployeeId ||
            (!item.IsSealed && this.beforeCycleExecutionEnd)) &&
          !this.isCheckListReadOnly();
        break;
      case VIEW_MODE.ADMIN:
        canRemove = !this.isCheckListReadOnly();
        break;
      case VIEW_MODE.MANAGER:
        canRemove = this.beforeCycleExecutionEnd && !this.isCheckListReadOnly();
        break;
      case VIEW_MODE.MY_STORY:
        canRemove = false;
        break;
    }

    return canRemove;
  }

  changeToggle(event, checkItem) {
    if (event.target.checked) {
      checkItem.Value = 100;
    } else {
      checkItem.Value = 0;
    }
    this.onChangeKR(checkItem);
  }

  isCheckListReadOnly() {
    if (
      (this.currentCycle.CycleInfo &&
        this.currentCycle.CycleInfo.Status === 'Unknown') ||
      (this.currentCycle.Status && this.currentCycle.Status === 'Unknown') ||
      this.objective.Deferred === true ||
      this.objective.Suspended === true
    ) {
      //EXPIRED
      return true;
    }
    switch (this.ViewMode) {
      case VIEW_MODE.ADMIN:
        return false;
        break;
      case VIEW_MODE.MANAGER:
        return false;
        break;
      case VIEW_MODE.INDIVIDUAL:
        return false;
        break;
      case VIEW_MODE.MY_STORY:
        return true;
        break;
    }

    return true;
  }
  /**
   * Condizione per cui lo sheet è readonly
   */
  isReadOnly() {
    if (
      (this.currentCycle.CycleInfo &&
        this.currentCycle.CycleInfo.Status === 'Unknown') ||
      (this.currentCycle.Status && this.currentCycle.Status === 'Unknown') ||
      this.objective.Deferred === true ||
      this.objective.Suspended === true
    ) {
      //EXPIRED
      return true;
    }

    if (this.ViewMode === VIEW_MODE.MANAGER) {
      return this.objective.Visibility === 'Private' || !this.entroAgreeDate;
    } else if (this.ViewMode === VIEW_MODE.ADMIN) {
      return this.objective.Visibility === 'Private';
    } else {
      if (this.objective.Sealed) return true; ///<<< caso assegnato in SET

      switch (this.whichView()) {
        case 'DEFINITION':
          if (this.ImNotAuthor) {
            switch (this.objective.DefinitionState) {
              case 0: //'Open'
                return false;
              case 1: //'Awaiting'
                return false;
              case 2: //'Set'
                return true;
            }
          } else {
            //'Not Assigned'
            return false;
          }

          break;
        case 'EXECUTION':
          if (this.inDefinitionPhase) {
            if (this.ImNotAuthor) {
              switch (this.objective.DefinitionState) {
                case 0: //'Open'
                  return false;
                case 1: //'Awaiting'
                  return false;
                case 2: //'Set'
                  return true;
              }
            } else {
              //'Not Assigned'
              return false;
            }
          } else {
            return this.objective.Visibility !== 'Private';
          }

          break;
        case 'EVALUATION':
          if (this.inDefinitionPhase) {
            if (this.ImNotAuthor) {
              switch (this.objective.DefinitionState) {
                case 0: //'Open'
                  return false;
                case 1: //'Awaiting'
                  return false;
                case 2: //'Set'
                  return true;
              }
            } else {
              //'Not Assigned'
              return false;
            }
          } else {
            return this.objective.Visibility !== 'Private';
          }

          break;
      }
    }

    return false;
  }

  onApprove() {
    let id = this.currentCycle.CycleId ?? this.currentCycle.Id;
    this.companyReportService
      .editCycleObjective(id, this.objective)
      .subscribe((result: any) => {
        this.companyReportService
          .approveObjective(this.objective)
          .subscribe((result: any) => {
            this.coreService.toasterMessage('success', 'Objective', 'Ok');
            this.updateObjectiveInSheets.emit(this.objective);
            this.onClose.next(true);
            this.bsModalRef.hide();
          });
      });
  }

  onUpdate() {
    let id = this.currentCycle.CycleId ?? this.currentCycle.Id;
    this.companyReportService
      .editCycleObjective(id, this.objective)
      .subscribe((result: any) => {
        this.coreService.toasterMessage('success', 'Objective', 'Ok');
        this.updateObjectiveInSheets.emit(this.objective);
        this.onClose.next(true);
        this.bsModalRef.hide();
      });
  }

  sendingAssign: boolean = false;
  public onAssign() {
    if (this.sendingAssign) return;
    console.log(this.sendingAssign);
    this.sendingAssign = true;

    const content: any = {
      YesCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.AssignConfirmOk'
      ),
      NoCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.AssignConfirmCancel'
      ),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.AssignConfirmTitle'
      ),
      subtitle: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.AssignConfirmSubTitle'
      ),
    };

    this.objective.CheckList.forEach((element: any) => {
      element.IsSealed = this.objective.DefinitionState === 2;
    });

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
      ignoreBackdropClick: true,
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        console.log(result);
        if (result) {
          let id = this.currentCycle.CycleId ?? this.currentCycle.Id;
          this.companyReportService
            .assignObjective(id, this.objective)
            .subscribe((r) => {
              this.updateObjectiveInSheets.emit(this.objective);
              if (this.isManager && this.olddata) {
                this.bsModalRef.hide();
                this.coreService
                  .getManagerEnteryPointBoxDetail('O9')
                  .subscribe((data: any) => {
                    this.bsModalRef = this.modalService.show(
                      ManagerOkrPopupComponent,
                      {
                        class: 'okr-top-align-pop',
                        ignoreBackdropClick: false,
                        initialState: {
                          response: data,
                          reOpen: this.olddata,
                        },
                      }
                    );
                    this.bsModalRef.content.closeBtnName = 'Close';
                  });
                // document.getElementById("popup-show").style.display = "block";
              }
              this.onClose.next(true);
              this.bsModalRef.hide();
            });
          // this.assignObjective.emit({id: id, objective: this.objective});
          // this.bsModalRef.hide();
        } else {
          this.sendingAssign = false;
        }
      }
    );
  }

  /**
   * Salvataggio oggetto
   * Metodo da invocare su ogni cambiamento
   */
  doSaveObject(elementChanged, event: any = undefined) {
    // console.log('doSaveObject', elementChanged, this.ViewMode);
    // this.onOutsideClick(event);
    // console.log(this.isSaveObjective);

    // if (!this.isSaveObjective) {
    //   return;
    // } else {
    //   console.log('else');

    if (elementChanged === 'DueDate') {
      if (this.objective.DueDate.indexOf('1970') !== -1) {
        this.objective.DueDate = moment(new Date(event)).format(
          'YYYY-MM-DDThh:mm:ss'
        );
        return;
      }
      this.objective.DueDate = moment(new Date(event)).format(
        'YYYY-MM-DDThh:mm:ss'
      );
      if (this.objective.DueDate.indexOf('1970') !== -1) {
        return;
      }
    }

    if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      console.log(
        '>>>>> SAVING <<<<<< ' +
          elementChanged +
          ' in ' +
          this.objective[elementChanged]
      );
      console.log(
        '>>>>> SAVING <<<<<< ' +
          elementChanged +
          ' in ' +
          this.originalObjective[elementChanged]
      );

      if (
        ((this.objective[elementChanged] ||
          this.objective[elementChanged] === 0) &&
          JSON.stringify(this.objective[elementChanged]) !==
            JSON.stringify(this.originalObjective[elementChanged])) ||
        (elementChanged === 'Visibility' && this.objective.Visibility === '') ||
        (elementChanged === 'Category' && this.objective.Category === '') ||
        (elementChanged === 'Name' && this.objective.Name === '')
      ) {
        if (this.objective.Weight)
          this.objective.Weight = Number(this.objective.Weight);

        console.log('ok');

        if (this.objective.Id) {
          this.objective.elementChanged = elementChanged;
          switch (elementChanged) {
            case 'CheckList':
              if (this.objective.Visibility !== 'Private') {
                this.objective.DefinitionState = 0;
              }
              this.companyReportService
                .SaveCheckList(this.objective.Id, this.objective.CheckList)
                .subscribe((result: any) => {
                  this.updateObjectiveInSheets.emit(this.objective);
                  this.originalObjective = JSON.parse(
                    JSON.stringify(this.objective)
                  );
                  // this.translate('refresh');
                });
              return;
              break;
            case 'Visibility':
              if (this.objective.Visibility !== 'Private') {
                this.objective.DefinitionState = 0;
              }
              break;

            case 'Name':
              if (this.objective.Visibility !== 'Private') {
                this.objective.DefinitionState = 0;
              }
              break;

            case 'Description':
              if (this.objective.Visibility !== 'Private') {
                this.objective.DefinitionState = 0;
              }
              break;

            case 'Category':
              if (this.objective.Visibility !== 'Private') {
                this.objective.DefinitionState = 0;
              }
              break;

            case 'DueDate':
              if (this.objective.Visibility !== 'Private') {
                this.objective.DefinitionState = 0;
              }
              break;
            case 'InclinationValue':
            case 'ChallengeValue':
              //NOP
              break;
            default:
          }
          let id = this.currentCycle.CycleId ?? this.currentCycle.Id;
          this.companyReportService
            .editCycleObjective(id, this.objective)
            .subscribe((result: any) => {
              this.updateObjectiveInSheets.emit(this.objective);
              this.originalObjective = JSON.parse(
                JSON.stringify(this.objective)
              );
            });
        } else {
          this.saveTheObjective().subscribe((result: any) => {
            this.objective.Id = result.Id;
            this.updateObjectiveInSheets.emit(this.objective);
            this.originalObjective = JSON.parse(JSON.stringify(this.objective));
            this.referralAction = 1;
          });
        }
      }
    }
    // }
    // this.translate('refresh');
  }

  saveTheObjective() {
    let id = this.currentCycle.CycleId ?? this.currentCycle.Id;
    return this.companyReportService.saveCycleObjective(id, this.objective);
  }

  isMineItem(authorId) {
    return authorId === this.userInfo.linkedEmployeeId;
  }

  getCurrentObjectiveState() {
    let currentObjectiveState: string;
    const fieldStateName = this.objectiveStateList[this.currentState].field;
    currentObjectiveState =
      this.objectiveStateList[this.currentState].values[
        this.objective[fieldStateName]
      ];
    //console.log('currentObjectiveState', currentObjectiveState);
    return currentObjectiveState;
  }

  /**
   *
   */
  whichView() {
    let view: string = '';

    if (
      this.inDefinitionPhase &&
      this.inExecutionPhase &&
      this.inEvaluationPhase &&
      this.currentState === 'DEFINITION_STATE'
    ) {
      return 'DEFINITION';
    }
    if (
      this.inDefinitionPhase &&
      this.inExecutionPhase &&
      this.inEvaluationPhase &&
      this.currentState === 'EXECUTION_STATE'
    ) {
      return 'EXECUTION';
    }
    if (
      this.inDefinitionPhase &&
      this.inExecutionPhase &&
      this.inEvaluationPhase &&
      this.currentState === 'EVALUATION_STATE'
    ) {
      return 'EVALUATION';
    }

    if (
      this.inDefinitionPhase &&
      this.inExecutionPhase &&
      this.currentState === 'DEFINITION_STATE'
    ) {
      return 'DEFINITION';
    }
    if (
      this.inDefinitionPhase &&
      this.inExecutionPhase &&
      this.currentState === 'EXECUTION_STATE'
    ) {
      return 'EXECUTION';
    }

    if (
      this.inExecutionPhase &&
      this.inEvaluationPhase &&
      this.currentState === 'DEFINITION_STATE'
    ) {
      return 'DEFINITION_VIEWONLY';
    }
    if (
      this.inExecutionPhase &&
      this.inEvaluationPhase &&
      this.currentState === 'EXECUTION_STATE'
    ) {
      return 'EXECUTION';
    }
    if (
      this.inExecutionPhase &&
      this.inEvaluationPhase &&
      this.currentState === 'EVALUATION_STATE'
    ) {
      return 'EVALUATION';
    }

    if (this.inDefinitionPhase && this.currentState === 'DEFINITION_STATE') {
      return 'DEFINITION';
    }

    if (this.inExecutionPhase && this.currentState === 'DEFINITION_STATE') {
      return this.ViewMode === VIEW_MODE.ADMIN
        ? 'DEFINITION'
        : 'DEFINITION_VIEWONLY';
    }

    if (this.inExecutionPhase && this.currentState === 'EXECUTION_STATE') {
      return 'EXECUTION';
    }

    if (
      this.inEvaluationPhase &&
      (this.currentState === 'DEFINITION_STATE' ||
        this.currentState === 'EXECUTION_STATE')
    ) {
      return this.ViewMode === VIEW_MODE.ADMIN
        ? this.currentState.replace('_STATE', '')
        : 'DEFINITION_VIEWONLY';
    }

    if (this.inEvaluationPhase && this.currentState === 'EVALUATION_STATE') {
      return 'EVALUATION';
    }

    return '';
  }

  isDeferredExtendedSource: boolean;
  isDeferredExtendedTarget: boolean;
  isDeferredExtendedSourceTarget: boolean;
  isPostponedSource: boolean;
  isPostponedTarget: boolean;

  isPostponed: boolean;

  public showModal(content: any): void {
    // console.log('ObjSheetPopupComponent showModal()', content);
    this.content = content;
    this.ImNotAuthor =
      this.content.objective.Author.Id !== this.userInfo.linkedEmployeeId;
    this.ImAuthor =
      this.content.objective.Author.Id == this.userInfo.linkedEmployeeId;

    this.ImOwner =
      this.content.objective.Employees.filter(
        (emp: any) => emp.Id == this.userInfo.linkedEmployeeId
      ).length > 0;

    this.objective = this.content.objective;
    this.translate('none');
    this.showTranslate = false;
    this.objectiveDueDate = new Date(this.objective.DueDate);
    if (this.objective.Comments) {
      this.objective.Comments.forEach((element) => {
        element.Date = this.sharedService.getLocalDate(element.Date);
      });
    }
    if (this.objective.Image) {
      const urlSegments = this.objective.Image.split('/');
      const lastSegment = urlSegments[urlSegments.length - 1];
      const imageName = lastSegment.split('?')[0];
      this.finalName = imageName;
    }
    // Per sicurezza faccio un cast da string a float
    this.objective.CheckList.forEach((item: any) => {
      item.MinValue = parseFloat(item.MinValue);
      item.Value = parseFloat(item.Value);
      item.TargetValue = parseFloat(item.TargetValue);
      item.showOkrSettings = false;
    });

    this.currentState = this.content.state;
    this.currentCycle = this.content.cycle;

    this.isDeferredExtendedSource =
      this.objective.Deferred &&
      this.objective.DeferrType === 'Extend' &&
      !this.objective.DeferrSourceId;
    this.isDeferredExtendedTarget =
      !this.objective.Deferred &&
      this.objective.DeferrType === 'Extend' &&
      this.objective.DeferrSourceId;
    this.isDeferredExtendedSourceTarget =
      this.objective.Deferred &&
      this.objective.DeferrType === 'Extend' &&
      this.objective.DeferrSourceId;

    this.isPostponedSource =
      this.objective.Deferred &&
      this.objective.DeferrType === 'Postpone' &&
      !this.objective.DeferrSourceId;
    this.isPostponedTarget =
      !this.objective.Deferred &&
      this.objective.DeferrType === 'Postpone' &&
      this.objective.DeferrSourceId;

    this.isPostponed = this.isPostponedSource || this.isPostponedTarget;

    // console.log('isPostponedSource', this.isPostponedSource);
    // console.log('isPostponedTarget', this.isPostponedTarget);

    // Content => state contiene lo stato attuale di visualizzazione, selezionato dal dropdown.
    const now = new Date();
    const today = this.coreService.ClearDateGMT(now);

    let AgreeDate = this.currentCycle.ManagerAggreedInterval
      ? this.coreService.ClearDateGMT(
          new Date(this.currentCycle.ManagerAggreedInterval.End),
          23,
          59
        )
      : undefined;

    this.entroAgreeDate = AgreeDate ? today <= AgreeDate : false;

    this.referral = this.content.referral;
    this.referralAction = this.content.referralAction;
    this.ViewMode = this.content.ViewMode;
    if (!this.currentCycle.Categories) {
      this.popupateCategories();
    }

    //this.cat = [{ id: '', itemName: '' }];
    this.cat = [];
    this.currentCycle.Categories.forEach((category: any) => {
      this.cat.push({
        id: category.Id,
        itemName: category.Name,
        image: category.Picture,
        IsCustom: category.IsCustom,
      });
    });

    // console.log(this.cat);

    let cycleStart;
    let cycleEnd;
    if (this.currentCycle.Period) {
      cycleStart = this.coreService.ClearDateGMT(
        new Date(this.currentCycle.Period.Start),
        0,
        0
      );
      cycleEnd = this.coreService.ClearDateGMT(
        new Date(this.currentCycle.Period.End),
        23,
        59
      );
    } else {
      cycleStart = this.coreService.ClearDateGMT(
        new Date(this.currentCycle.CycleInfo.Period.Start),
        0,
        0
      );
      cycleEnd = this.coreService.ClearDateGMT(
        new Date(this.currentCycle.CycleInfo.Period.End),
        23,
        59
      );
    }

    let definitionFrom = this.coreService.ClearDateGMT(
      new Date(this.currentCycle.IndividualDefinitionInterval.Start),
      0,
      0
    );
    let definitionTo = this.coreService.ClearDateGMT(
      new Date(this.currentCycle.IndividualDefinitionInterval.End),
      23,
      59
    );

    let evaluationFrom = this.coreService.ClearDateGMT(
      new Date(this.currentCycle.AdminAppraisalInterval.Start),
      0,
      0
    );
    let evaluationTo = this.coreService.ClearDateGMT(
      new Date(this.currentCycle.AdminAppraisalInterval.End),
      23,
      59
    );

    if (this.currentCycle.WhoCanEdit === 'Reduced') {
      //Lascia solo le visibilità Reduced,Limited,Private
      this.visibilityList = this.visibilityList.filter(
        (x) => x.Value !== 'Public'
      );
    } else if (this.currentCycle.WhoCanEdit === 'Limited') {
      //Lascia solo le visibilità Limited,Private (Poi hanno cambiato e anche quetso caso è identivo a quello sopra)
      this.visibilityList = this.visibilityList.filter(
        (x) => x.Value !== 'Public'
      );
    }

    switch (this.ViewMode) {
      case VIEW_MODE.INDIVIDUAL:
        this.visibilityList.push({
          id: '3',
          itemName: this.privateCaption,
          Value: 'Private',
          Hint: this.coreService.getTranslation(
            'OBJECTIVE_SHEET_POPUP.VisibilityPrivateHint'
          ),
          Icon: 'fa fa-eye-slash',
        });
        break;
      case VIEW_MODE.MANAGER:
        definitionFrom = this.coreService.ClearDateGMT(
          new Date(this.currentCycle.ManagerDefinitionInterval.Start),
          0,
          0
        );
        definitionTo = this.coreService.ClearDateGMT(
          new Date(this.currentCycle.ManagerDefinitionInterval.End),
          23,
          59
        );

        evaluationFrom = this.coreService.ClearDateGMT(
          new Date(this.currentCycle.ManagerAppraisalInterval.Start),
          0,
          0
        );
        evaluationTo = this.coreService.ClearDateGMT(
          new Date(this.currentCycle.ManagerAppraisalInterval.End),
          23,
          59
        );
        break;
      case VIEW_MODE.ADMIN:
        definitionFrom = this.coreService.ClearDateGMT(
          new Date(this.currentCycle.AdminDefinitionInterval.Start),
          0,
          0
        );
        definitionTo = this.coreService.ClearDateGMT(
          new Date(this.currentCycle.AdminDefinitionInterval.End),
          23,
          59
        );
    }

    this.inDefinitionPhase = today >= definitionFrom && today <= definitionTo;
    this.afterDefinitionPhase = today > definitionTo;
    this.inEvaluationPhase = today >= evaluationFrom && today <= evaluationTo;
    this.inExecutionPhase = today >= cycleStart && today <= cycleEnd;
    this.beforeCycleExecutionEnd = today <= cycleEnd;
    this.beforeEvaluationEnd = today <= evaluationTo;
    this.fromExecutionPhase = today >= cycleStart;
    this.afterEvaluation = today > evaluationTo;

    this.objectiveState = this.getCurrentObjectiveState();
    this.selectedCategory = this.objective.Category
      ? [
          {
            id: this.objective.Category.Id,
            itemName: this.objective.Category.Name,
            image: this.objective.Category.Picture,
          },
        ]
      : [];

    if (this.objective.Visibility)
      this.selectedVisibility = [
        this.allVisibilityList.find(
          (item: any) => item.Value === this.objective.Visibility
        ),
      ];

    this.originalObjective = JSON.parse(JSON.stringify(this.objective));

    // Se non sono più in definition phase e sono INDIVIDUAL forzo PRIVATE;
    if (
      ((!this.objective.Id && !this.inDefinitionPhase) ||
        (!this.inDefinitionPhase && this.objective.Visibility === 'Private')) &&
      this.ViewMode === VIEW_MODE.INDIVIDUAL
    ) {
      this.visibilityList = [
        {
          id: '3',
          itemName: this.privateCaption,
          Value: 'Private',
          Hint: this.coreService.getTranslation(
            'OBJECTIVE_SHEET_POPUP.VisibilityPrivateHint'
          ),
          Icon: 'fa fa-eye-slash',
        },
      ];
      this.selectedVisibility = [
        {
          id: '3',
          itemName: this.privateCaption,
          Value: 'Private',
          Hint: this.coreService.getTranslation(
            'OBJECTIVE_SHEET_POPUP.VisibilityPrivateHint'
          ),
          Icon: 'fa fa-eye-slash',
        },
      ];
      this.objective.Visibility = 'Private';
    }

    if (this.ViewMode !== VIEW_MODE.INDIVIDUAL) {
      $('#chk1').prop('checked', this.objective.DefinitionState == 2);
    }

    this.amsd_settings_categories = {
      text: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.NoCategorySelected'
      ),
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: true,
      enableFilterSelectAll: false,
      classes: 'amsdDropdown',
      showCheckbox: false,
      singleSelection: true,
      disabled:
        this.objective.Suspended || this.showCategories() === 'VIEW_ONLY',
      position: 'bottom',
      autoPosition: false,
    };

    this.amsd_settings_visibility = {
      selectAllText: '',
      unSelectAllText: '',
      enableSearchFilter: false,
      enableFilterSelectAll: false,
      classes: 'amsdDropdown',
      showCheckbox: false,
      singleSelection: true,
      disabled:
        this.objective.Suspended || this.showVisibility() === 'VIEW_ONLY',
      position: 'bottom',
      autoPosition: false,
    };

    // TABS
    // Networks
    this.a2mSettingsNetworks = {
      text: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.NetworksTab.SearchOKR'
      ),
      selectAllText: 'selectAllText',
      unSelectAllText: 'unSelectAllText',
      filterSelectAllText: 'filterSelectAllText',
      filterUnSelectAllText: 'filterUnSelectAllText',
      searchPlaceholderText: 'searchPlaceholderText',

      classes: 'availableLinksDropDown',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      showCheckbox: false,
      singleSelection: false,
      lazyLoading: true,
      labelKey: 'name',
    };

    this.getUpLinks();
  }

  onScroll(event) {
    if (this.datepicker && this.datepicker.isOpen) {
      if (event.target.scrollTop === 0) {
        this.datepicker?.hide();
      } else {
        const el = document.querySelector('modal-container');
        el.dispatchEvent(new Event('scroll'));
      }
    }
  }

  popupateCategories() {
    this.currentCycle.Categories = [];
    if (
      this.currentCycle.CustomCategories &&
      this.currentCycle.CustomCategories.length > 0
    ) {
      this.currentCycle.CustomCategories.forEach((c) => {
        let cat = {
          Id: c.Id,
          IsCustom: true,
          Picture: c.Picture,
          Name: c.Name[this.userInfo.language].Text,
        };
        this.currentCycle.Categories.push(cat);
      });
    }

    if (
      this.currentCycle.GlobalCategories &&
      this.currentCycle.GlobalCategories.length > 0
    ) {
      this.currentCycle.GlobalCategories.forEach((c) => {
        if (c.IsSelected) {
          let cat = {
            Id: c.Id,
            IsCustom: false,
            Picture: c.Picture,
            Name: c.Name[this.userInfo.language].Text,
          };
          this.currentCycle.Categories.push(cat);
        }
      });
    }
  }

  public onSave(): void {
    const content: any = {
      YesCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RequestSaveConfirmOk'
      ),
      NoCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RequestSaveConfirmCancel'
      ),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RequestSaveConfirmTitle'
      ),
      subtitle: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RequestSaveConfirmSubTitle'
      ),
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          if (this.objective.Id) {
            this.companyReportService
              .requestApproval(this.objective.Id)
              .subscribe(
                (r) => {
                  this.objective.elementChanged = 'ApprovalStatus';
                  this.objective.ApprovalStatus = 'Approved';
                  this.updateObjectiveInSheets.emit(this.objective);
                  this.originalObjective = JSON.parse(
                    JSON.stringify(this.objective)
                  );
                  this.coreService.toasterMessage(
                    'success',
                    'Objectives',
                    'Ok'
                  );

                  this.bsModalRef.hide();
                },
                (err: any) => {
                  this.coreService.toasterMessage(
                    'error',
                    'Objectives',
                    'Error'
                  );
                }
              );
          } else {
            this.companyReportService
              .saveCycleObjective(this.currentCycle.CycleId, this.objective)
              .subscribe((result: any) => {
                this.objective.Id = result.Id;
                this.companyReportService
                  .requestApproval(this.objective.Id)
                  .subscribe(
                    (r) => {
                      this.objective.elementChanged = 'ApprovalStatus';
                      this.objective.ApprovalStatus = 'Approved';
                      this.updateObjectiveInSheets.emit(this.objective);
                      this.originalObjective = JSON.parse(
                        JSON.stringify(this.objective)
                      );
                      this.coreService.toasterMessage(
                        'success',
                        'Objectives',
                        'Ok'
                      );

                      this.bsModalRef.hide();
                    },
                    (err: any) => {
                      this.coreService.toasterMessage(
                        'error',
                        'Objectives',
                        'Error'
                      );
                    }
                  );
              });
          }
        }
      }
    );
  }

  public onRequireApproval(): void {
    const content: any = {
      YesCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RequestApprovalConfirmOk'
      ),
      NoCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RequestApprovalConfirmCancel'
      ),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RequestApprovalConfirmTitle'
      ),
      subtitle: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RequestApprovalConfirmSubTitle'
      ),
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          if (this.objective.Id) {
            this.companyReportService
              .requestApproval(this.objective.Id)
              .subscribe(
                (r) => {
                  this.objective.elementChanged = 'ApprovalStatus';
                  this.objective.ApprovalStatus = 'ApprovalRequested';
                  this.updateObjectiveInSheets.emit(this.objective);
                  this.originalObjective = JSON.parse(
                    JSON.stringify(this.objective)
                  );
                  this.coreService.toasterMessage(
                    'success',
                    'Objectives',
                    'Ok'
                  );

                  this.bsModalRef.hide();
                },
                (err: any) => {
                  this.coreService.toasterMessage(
                    'error',
                    'Objectives',
                    'Error'
                  );
                }
              );
          } else {
            this.companyReportService
              .saveCycleObjective(this.currentCycle.CycleId, this.objective)
              .subscribe((result: any) => {
                this.objective.Id = result.Id;
                this.companyReportService
                  .requestApproval(this.objective.Id)
                  .subscribe(
                    (r) => {
                      this.objective.elementChanged = 'ApprovalStatus';
                      this.objective.ApprovalStatus = 'ApprovalRequested';
                      this.updateObjectiveInSheets.emit(this.objective);
                      this.originalObjective = JSON.parse(
                        JSON.stringify(this.objective)
                      );
                      this.coreService.toasterMessage(
                        'success',
                        'Objectives',
                        'Ok'
                      );

                      this.bsModalRef.hide();
                    },
                    (err: any) => {
                      this.coreService.toasterMessage(
                        'error',
                        'Objectives',
                        'Error'
                      );
                    }
                  );
              });
          }
        }
      }
    );
  }

  public clone() {
    const content: any = {
      viewMode: this.ViewMode,
      currentCycle: this.currentCycle,
      showClone: this.getShowClone(),
    };

    const modal = this.modalService.show(CloneCycleDialogComponent, {
      class: 'clone-dialog',
    });
    (<CloneCycleDialogComponent>modal.content).showModal(content);
    (<CloneCycleDialogComponent>modal.content).onClose.subscribe(
      (result: any) => {
        if (result.clone) {
          const toClone = this.objective;
          const targetCycleId = result.selectedCycle
            ? result.selectedCycle.Id
            : this.currentCycle.Id;
          const targetType = result.target;
          this.companyReportService
            .cloneObjective(
              toClone,
              targetCycleId,
              this.whichView(),
              this.ViewMode,
              targetType
            )
            .subscribe((resultClone: any) => {
              console.log('resultClone', resultClone);
              /*
              //this.updateObjectiveInSheets.emit(this.objective);
              this.onClose.next(true);
              this.bsModalRef.hide();
            */
            });
        }
      }
    );
  }

  public deferr() {
    const content: any = {
      objective: this.objective,
      userInfo: this.userInfo,
    };

    const modal = this.modalService.show(DeferDialogComponent, {
      class: 'deferr-dialog',
    });
    (<DeferDialogComponent>modal.content).showModal(content);
    (<DeferDialogComponent>modal.content).onClose.subscribe((result: any) => {
      if (result.deferr) {
        this.objective['DeferrType'] = result.DeferralType;
        this.objective['DeferrDueDate'] = result.DueDate;
        this.companyReportService
          .deferrObjective(this.objective, result.selectedCycleId)
          .subscribe((r: any) => {
            this.objective.Deferred = true;
            this.objective['DueDate'] = result.DueDate;
            //this.objective.EvaluationState = 2;
            this.updateObjectiveInSheets.emit(this.objective);
            this.onClose.next(true);
            this.bsModalRef.hide();
          });
      }
    });
  }

  public revoke() {
    const content: any = {
      YesCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.SuspendConfirmOk'
      ),
      NoCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.SuspendConfirmCancel'
      ),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.SuspendConfirmTitle'
      ),
      subtitle: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.SuspendConfirmSubTitle'
      ),
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.companyReportService
            .suspendObjective(this.objective)
            .subscribe((r) => {
              this.objective.Suspended = true;
              // this.objective.EvaluationState = 2;
              this.updateObjectiveInSheets.emit(this.objective);
              this.onClose.next(true);
              this.bsModalRef.hide();
            });
        }
      }
    );
  }

  public restore() {
    const content: any = {
      YesCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RestoreConfirmOk'
      ),
      NoCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RestoreConfirmCancel'
      ),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RestoreConfirmTitle'
      ),
      subtitle: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.RestoreConfirmSubTitle'
      ),
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.companyReportService
            .restoreObjective(this.objective)
            .subscribe((r) => {
              this.objective.Suspended = false;
              // this.objective.EvaluationState = 2;
              this.updateObjectiveInSheets.emit(this.objective);
              this.onClose.next(true);
              this.bsModalRef.hide();
            });
        }
      }
    );
  }

  public onCancel(): void {
    if (this.isManager && this.olddata) {
      this.bsModalRef.hide();
      this.coreService
        .getManagerEnteryPointBoxDetail('O9')
        .subscribe((data: any) => {
          this.bsModalRef = this.modalService.show(ManagerOkrPopupComponent, {
            class: 'okr-top-align-pop',
            ignoreBackdropClick: false,
            initialState: {
              response: data,
              reOpen: this.olddata,
            },
          });
          this.bsModalRef.content.closeBtnName = 'Close';
        });
      // document.getElementById("popup-show").style.display = "block";
    } else {
      this.bsModalRef.hide();
      return;
      // document.getElementById("popup-show").style.display = "block";
    }
    this.onClose.next(false);
  }

  getStatusLabel() {
    if (
      this.whichView() === 'EVALUATION' &&
      (this.objective.Deferred || this.objective.Suspended)
    ) {
      if (this.objective.Deferred)
        return this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.Deferred'
        );
      if (this.objective.Suspended)
        return this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.Suspended'
        );
    } else {
      const fieldStateName = this.objectiveStateList[this.currentState].field;
      let langKey =
        'INDIVIDUAL_OBJECTIVES.' +
        this.currentState +
        '.COL_' +
        this.objective[fieldStateName];
      let s: any = this.coreService.getTranslation(langKey);
      return s.Name;
    }
  }

  onCategorySelect(event: any) {
    console.log('onCategorySelect', event);
    if (!event.id) {
      this.OnCategoryDeSelect(event);
      return;
    }
    this.objective.Category = {
      Id: event.id,
      Picture: event.image,
      IsCustom: event.IsCustom,
      Name: event.itemName,
    };
    this.doSaveObject('Category');
  }

  OnCategoryDeSelect(event: any) {
    // console.log('OnCategoryDeSelect', event);
    this.objective.Category = '';
    this.doSaveObject('Category');
  }

  onVisibilitySelect(event: any) {
    // console.log('onItemSelect', event);
    this.objective.Visibility = event.Value;
    this.doSaveObject('Visibility');
  }

  OnVisibilityDeSelect(event: any) {
    // console.log('OnItemDeSelect', event, this.visibilityList[0].Value);
    this.objective.Visibility = this.visibilityList[0].Value;
    this.objective.Visibility = '';
    this.doSaveObject('Visibility');
  }

  selectEmployee(isLimited: boolean) {
    let content: any = {
      YesCaption: this.coreService.getTranslation('CONFIRM'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('Messages.SureDelete'),
      selected: this.objective.Employees,
    };

    if (isLimited) {
      content.selected =
        JSON.parse(JSON.stringify(this.objective.LimitedViewer)) || [];
    }
    content.externalFilter = this.currentCycle.Partecipants;

    const modal = this.modalService.show(EmployeeDialogComponent, {
      class: 'employee-selection-dialog',
    });
    (<EmployeeDialogComponent>modal.content).showModal(content);
    (<EmployeeDialogComponent>modal.content).onClose.subscribe(
      (result: any) => {
        if (result.success) {
          // console.log('EmployeeDialogComponent result', result);
          if (isLimited) {
            this.objective.LimitedViewer = result.selected;
            this.doSaveObject('LimitedViewer');
          } else {
            this.objective.Employees = result.selected;
          }
        }
      }
    );
  }

  removeViewer(viewer: any) {
    this.objective.LimitedViewer = this.objective.LimitedViewer.filter(
      (vw: any) => vw.Id !== viewer.Id
    );
    this.doSaveObject('LimitedViewer');
  }

  removeEmployee(emp: any) {
    this.objective.Employees = this.objective.Employees.filter(
      (vw: any) => vw.Id !== emp.Id
    );
  }

  getObjectivesByCategory(categoryId: any) {
    let objectivesList: any = [];
    if (this.currentCycle.Details) {
      let val = this.currentCycle.Details.PerCategory.find(
        (x) => x.Id === categoryId
      );
      if (val) {
        return val.Value.Total;
      } else {
        return 0;
      }
    } else {
      switch (this.whichView()) {
        case 'DEFINITION':
          this.currentCycle.DefinitionObjectives.forEach((element: any) => {
            const exists = this.currentCycle.AllObjectives.find(
              (obj: any) => obj.$id === element.$ref
            );
            if (exists) objectivesList.push(exists);
          });
          break;
        case 'EXECUTION':
          this.currentCycle.ProgressObjectives.forEach((element: any) => {
            const exists = this.currentCycle.AllObjectives.find(
              (obj: any) => obj.$id === element.$ref
            );
            if (exists) objectivesList.push(exists);
          });
          break;
        case 'EVALUATION':
          this.currentCycle.EvaluationObjectives.forEach((element: any) => {
            const exists = this.currentCycle.AllObjectives.find(
              (obj: any) => obj.$id === element.$ref
            );
            if (exists) objectivesList.push(exists);
          });
          break;
      }
      let retList = objectivesList.filter((obj: any) => {
        if (obj.Category) {
          obj.Category.Id === categoryId;
        }
      });
      return retList.length;
    }
  }

  okrStart: number = 0;
  okrTarget: number = 0;
  okrValue: boolean = false;
  okrMeasureType: any = 0;
  okrMeasureName: string = '%';
  showOkrSettings: boolean = false;
  addChecklistItem() {
    console.log('addChecklistItem', this.newCheckItem.nativeElement.value);
    this.okrMeasureType = parseInt(this.okrMeasureType);
    if (
      !this.newCheckItem.nativeElement.value ||
      this.newCheckItem.nativeElement.value.trim().length == 0
    )
      return;

    const value = this.okrMeasureType == 2 ? (this.okrValue ? 100 : 0) : 0;

    let body = {
      Author: {
        Id: this.userInfo.linkedEmployeeId,
        Picture: this.userInfo.imageUrl,
        Name: this.userInfo.fullName,
      },
      DueDate: null,
      Index: this.objective.CheckList.length,
      IsSealed: this.ViewMode != VIEW_MODE.INDIVIDUAL,
      Status: 0,
      Text: this.newCheckItem.nativeElement.value,

      // Nuovi valori per nuove api KR
      MinValue: this.okrStart ? this.okrStart : 0,
      TargetValue: this.okrTarget ? this.okrTarget : 100,
      Value: value,
      MeasureType: this.okrMeasureType ? this.okrMeasureType : 0,
      MeasureName: this.okrMeasureName ? this.okrMeasureName : '%',
      Range: [0, 0],
    };
    console.log('addChecklistItem', body);

    this.objective.CheckList.push(body);
    this.enableAddChecklistItem = false;
    this.doSaveObject('CheckList');

    this.okrMeasureType = 0;
    this.okrStart = 0;
    this.okrTarget = 0;
    this.okrMeasureName = '%';
    this.showOkrSettings = false;
  }

  saveCheckList(checkItem) {
    checkItem.isEditing = false;
    this.doSaveObject('CheckList');
  }

  lostFocusCheckListIndividual() {
    if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      this.doSaveObject('CheckList');
    }
  }

  handleEditDropdownchange(checkItem) {
    checkItem.MinValue = 0;
    checkItem.TargetValue = 0;
    checkItem.MeasureName = '%';
    checkItem.MeasureType = parseInt(checkItem.MeasureType);
  }

  handleAddDropdownchange() {
    this.okrStart = 0;
    this.okrTarget = 0;
    this.okrMeasureName = '%';
    console.log(this.okrMeasureType);
    this.okrMeasureType = parseInt(this.okrMeasureType);
  }

  showSettingsSingleItem(item: any) {
    const status = !item.showOkrSettings;
    this.objective.CheckList.forEach((element) => {
      element.showOkrSettings = false;
    });

    setTimeout(() => {
      item.showOkrSettings = status;
    }, 100);
    this.showOkrSettingsEdit = status;
  }

  addInsertChecklistItem(event: any) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 13) {
      this.newCheckItem.nativeElement.value += '\n';
      // } else if (event.keyCode === 13) {
      //   this.addChecklistItem();
    }
  }

  removeCheckItem(index: number) {
    this.objective.CheckList.splice(index, 1);
    this.doSaveObject('CheckList');
  }

  setChekItem(item) {
    console.log('setChekItem', item.Status, this.whichView());
    if (
      this.whichView() === 'EXECUTION' &&
      //this.objective.ProgressState === 1 &&
      this.ViewMode === VIEW_MODE.INDIVIDUAL
    ) {
      if (item.Status === 0) item.Status = 2;
      else item.Status = 0;

      this.doSaveObject('CheckList');
    }
  }

  getEvalArr() {
    let arr = [];

    for (var i = 1; i <= this.maxScale; i++) {
      arr.push(i);
    }

    return arr;
  }

  // removeCategory() {
  //   this.selectedCategory = undefined;
  // }

  addNewCheckItem(event: any) {
    if (event.keyCode === 13) {
      this.objective.CheckList.push({
        Author: {
          Id: this.userInfo.linkedEmployeeId,
          Picture: this.userInfo.imageUrl,
          Name: this.userInfo.fullName,
        },
        Index: this.objective.CheckList.length,
        Text: this.newCheckItem.nativeElement.value,
        Status: 0,
        IsSealed: false,
        DueDate: null,
      });
      this.doSaveObject('CheckList');
      this.enableAddChecklistItem = false;
    }
  }

  addNewActivity(event: any) {
    let comment = {
      Author: {
        Id: this.userInfo.linkedEmployeeId,
        Picture: this.userInfo.imageUrl,
        Name: this.userInfo.fullName,
      },
      Text: this.addActivity.nativeElement.value.trim(),
      Date: new Date(),
    };

    if (event.keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
      if (!this.addActivity.nativeElement.value) {
        return;
      }
    }

    if ((event.ctrlKey || event.metaKey) && event.keyCode === 13) {
      console.log('Passo quì');
      this.insertNewLineAtCursor();
    } else if (event.keyCode === 13) {
      console.log('Passo quì giusto');
      this.objective.Comments.unshift(comment);
      this.companyReportService
        .addCommentToObjective(this.objective.Id, comment)
        .subscribe((arg) => {});
      this.addActivity.nativeElement.value = '';
      this.doSaveObject('Comments');
    }
  }

  insertNewLineAtCursor() {
    const textarea = this.addActivity.nativeElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const value = textarea.value;

    textarea.value = value.substring(0, start) + '\n' + value.substring(end);
    textarea.selectionStart = textarea.selectionEnd = start + 1;

    // Trigger change detection if necessary
    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });
    textarea.dispatchEvent(event);
  }

  editNewActivity(event, activity) {
    if (event.keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
      activity.isEditing = false;
    }
  }

  deleteActivity(activity) {
    this.companyReportService
      .deleteCommentInObjective(activity.$id)
      .subscribe(() => {});
  }

  deleteObject() {
    const content: any = {
      YesCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.DeleteObjectiveConfirmOk'
      ),
      NoCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.DeleteObjectiveConfirmCancel'
      ),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.DeleteObjectiveConfirmTitle'
      ),
      subtitle: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.DeleteObjectiveConfirmSubTitle'
      ),
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          if (this.objective.Id) {
            this.companyReportService
              .deleteCycleObjective(this.objective.Id)
              .subscribe((r) => {
                this.removeObjectiveInSheets.emit(this.objective);
                //CLOSE THE POPUP
                this.onClose.next(true);
                this.bsModalRef.hide();
              });
          } else {
            this.removeObjectiveInSheets.emit(this.objective);
            //CLOSE THE POPUP
            this.onClose.next(false);
            this.bsModalRef.hide();
          }
        }
      }
    );
  }

  setEvaluationValue(type, value) {
    if (this.objective[type] === value) {
      this.objective[type] = -1;
    } else {
      this.objective[type] = value;
    }

    this.doSaveObject(type);
  }

  changeDefinitionState(objective: any) {
    if (objective.DefinitionState === 0) {
      objective.DefinitionState = 2;
    } else {
      objective.DefinitionState = 0;
    }

    $('#chk1').prop('checked', objective.DefinitionState == 2);
    this.ref.detectChanges();
  }

  showDeferr() {
    let vw = this.whichView();
    if (
      this.objective.Suspended ||
      this.objective.Deferred ||
      this.objective.IsExpired
    )
      return false;

    return (
      (this.inEvaluationPhase || this.inExecutionPhase) &&
      ((this.ViewMode === VIEW_MODE.INDIVIDUAL &&
        this.objective.Visibility === 'Private') ||
        this.ViewMode !== VIEW_MODE.INDIVIDUAL)
    );
  }

  showRevoke() {
    if (
      this.objective.Suspended ||
      this.objective.Deferred ||
      this.objective.IsExpired ||
      this.objective.Visibility === 'Private'
    )
      return false;

    return (
      (this.inEvaluationPhase || this.inExecutionPhase) &&
      this.ViewMode !== VIEW_MODE.INDIVIDUAL
    );
  }

  showClone() {
    let x = this.getShowClone();

    return x.canCloneToThis || x.canCloneToOther;
  }

  getShowClone(): any {
    if (this.ViewMode === VIEW_MODE.MY_STORY) {
      return false;
    }

    let canCloneToThis = true;
    let canCloneToOther = true;

    let vw = this.whichView();
    switch (vw) {
      case 'EXECUTION':
        if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
          canCloneToThis = this.objective.Visibility === 'Private';
          canCloneToOther = true;
        } else if (this.ViewMode === VIEW_MODE.MANAGER) {
          canCloneToThis = false;
          canCloneToOther = this.objective.Visibility !== 'Private';
        } else {
          canCloneToThis = this.objective.Visibility !== 'Private';
          canCloneToOther = this.objective.Visibility !== 'Private';
        }
        break;
      case 'DEFINITION':
        if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
          canCloneToThis = true;
          canCloneToOther = true;
        } else if (this.ViewMode === VIEW_MODE.MANAGER) {
          canCloneToThis = this.objective.Visibility !== 'Private';
          canCloneToOther = this.objective.Visibility !== 'Private';
        } else {
          canCloneToThis = this.objective.Visibility !== 'Private';
          canCloneToOther = this.objective.Visibility !== 'Private';
        }
        break;
      case 'EVALUATION':
        canCloneToThis = false;
        if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
          canCloneToOther = true;
        } else if (this.ViewMode === VIEW_MODE.MANAGER) {
          canCloneToOther = this.objective.Visibility !== 'Private';
        } else {
          canCloneToOther = this.objective.Visibility !== 'Private';
        }
        break;
      default:
        canCloneToThis = false;
        canCloneToOther = false;
        break;
    }

    return { canCloneToThis: canCloneToThis, canCloneToOther: false }; // TODO canCloneToOther fisso a FALSE per il momento
  }

  showRestore() {
    if (this.ViewMode === VIEW_MODE.MY_STORY) return false;

    let vw = this.whichView();
    if (
      !this.objective.Suspended ||
      this.objective.IsExpired ||
      vw === 'DEFINITION'
    )
      return false;

    return (
      (this.ViewMode === VIEW_MODE.INDIVIDUAL &&
        this.objective.Visibility === 'Private') ||
      (this.ViewMode !== VIEW_MODE.INDIVIDUAL &&
        this.objective.Visibility !== 'Private')
    );
  }

  /*___________________________________________________________

  _____  _____            _____            _____  _____   ____  _____
 |  __ \|  __ \     /\   / ____|   ___    |  __ \|  __ \ / __ \|  __ \
 | |  | | |__) |   /  \ | |  __   ( _ )   | |  | | |__) | |  | | |__) |
 | |  | |  _  /   / /\ \| | |_ |  / _ \/\ | |  | |  _  /| |  | |  ___/
 | |__| | | \ \  / ____ \ |__| | | (_>  < | |__| | | \ \| |__| | |
 |_____/|_|  \_\/_/    \_\_____|  \___/\/ |_____/|_|  \_\\____/|_|


 _______________________________________________________________*/

  onCheckListDrop(event: DndDropEvent) {
    this.objective.CheckList.splice(
      event.index,
      0,
      this.objective.CheckList.splice(event.data, 1)[0]
    );
    //Recalculate chekclist indexes
    let c = 0;
    this.objective.CheckList.forEach((item) => {
      item.index = c++;
    });
    this.doSaveObject('CheckList');
  }

  /*___________________________________________________________
             _                               _    _____ _               _
    /\      | |                             | |  / ____| |             | |
   /  \   __| |_   ____ _ _ __   ___ ___  __| | | |    | |__   ___  ___| | __
  / /\ \ / _` \ \ / / _` | '_ \ / __/ _ \/ _` | | |    | '_ \ / _ \/ __| |/ /
 / ____ \ (_| |\ V / (_| | | | | (_|  __/ (_| | | |____| | | |  __/ (__|   <
/_/    \_\__,_| \_/ \__,_|_| |_|\___\___|\__,_|  \_____|_| |_|\___|\___|_|\_\
_______________________________________________________________*/

  showCoverEdit() {
    let show: boolean = false;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = true;
      } else if (this.currentState === 'DEFINITION_STATE') {
        show = true;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        if (this.inDefinitionPhase) {
          show = true;
        }
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.whichView() === 'DEFINITION') {
          show = true;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = true;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (
          this.whichView() === 'DEFINITION' &&
          [0, 1].includes(this.objective.DefinitionState)
        ) {
          show = true;
        } else if (
          this.objective.Visibility === 'Private' &&
          [2].includes(this.objective.DefinitionState)
        ) {
          show = true;
        }
      }
    }

    return show;
  }

  nameReadOnly() {
    let readonly: boolean = true;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        readonly = false;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState === 'DEFINITION_STATE') {
          readonly = false;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        readonly = false;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.whichView() === 'DEFINITION') {
          readonly = false;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        readonly = false;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (
          this.whichView() === 'DEFINITION' &&
          [0, 1].includes(this.objective.DefinitionState)
        ) {
          readonly = false;
        } else if (
          this.objective.Visibility === 'Private' &&
          [2].includes(this.objective.DefinitionState)
        ) {
          readonly = false;
        }
      }
    }

    return readonly;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   * ICONS
   */
  showChallengeInclinationPre() {
    let show: VIEW_TYPE = VIEW_TYPE.HIDDEN;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState !== 'EVALUATION_STATE') {
          show = VIEW_TYPE.ICONS;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState !== 'EVALUATION_STATE') {
          show = VIEW_TYPE.ICONS;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.whichView() === 'DEFINITION') {
          show = VIEW_TYPE.EDIT;
        } else if (this.currentState === 'DEFINITION_STATE') {
          show = VIEW_TYPE.VIEW_ONLY;
        } else if (
          this.currentState !== 'DEFINITION_STATE' &&
          this.beforeEvaluationEnd
        ) {
          show = VIEW_TYPE.ICONS;
        }
      }
    }

    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showWeight() {
    let show: VIEW_TYPE = VIEW_TYPE.HIDDEN;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState === 'DEFINITION_STATE') {
          show = VIEW_TYPE.EDIT;
        } else {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      }
      if (
        this.objective.ApprovalStatus !== 'Approved' &&
        !this.currentCycle.CycleInfo.ActivePhases.includes('Definition')
      ) {
        show = VIEW_TYPE.VIEW_ONLY;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        if (this.entroAgreeDate) {
          show = VIEW_TYPE.EDIT;
        } else {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState === 'DEFINITION_STATE' && this.entroAgreeDate) {
          show = VIEW_TYPE.EDIT;
        } else {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        show = VIEW_TYPE.VIEW_ONLY;
      }
    }

    return this.currentCycle.EnableWheigthing ? show : VIEW_TYPE.HIDDEN;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   * ICONS
   */
  showChallengeInclinationPost() {
    let show: VIEW_TYPE = VIEW_TYPE.HIDDEN;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState === 'EVALUATION_STATE') {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState === 'EVALUATION_STATE') {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (
          this.beforeEvaluationEnd &&
          this.currentState === 'EVALUATION_STATE'
        ) {
          show = VIEW_TYPE.EDIT;
        } else if (this.currentState === 'EVALUATION_STATE') {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      }
    }
    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showStatus() {
    let show: VIEW_TYPE = VIEW_TYPE.HIDDEN;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        if (this.inDefinitionPhase) {
          show = VIEW_TYPE.EDIT;
        } else {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
    }

    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showDescription() {
    let show: VIEW_TYPE = VIEW_TYPE.VIEW_ONLY;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.currentState === 'DEFINITION_STATE') {
        show = VIEW_TYPE.EDIT;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.whichView() == 'DEFINITION') {
          show = VIEW_TYPE.EDIT;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (
          this.whichView() === 'DEFINITION' &&
          [0, 1].includes(this.objective.DefinitionState)
        ) {
          show = VIEW_TYPE.EDIT;
        } else if (
          this.objective.Visibility === 'Private' &&
          [2].includes(this.objective.DefinitionState)
        ) {
          show = this.whichView().startsWith('DEFINITION')
            ? VIEW_TYPE.EDIT
            : VIEW_TYPE.VIEW_ONLY;
        }
      }
    }

    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showEmployees() {
    let show: VIEW_TYPE = VIEW_TYPE.VIEW_ONLY;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      show = VIEW_TYPE.HIDDEN;
    } else if (this.ViewMode === VIEW_MODE.MY_STORY) {
      show = VIEW_TYPE.VIEW_ONLY;
    }

    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showCategories() {
    let show: VIEW_TYPE = VIEW_TYPE.VIEW_ONLY;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.currentState === 'DEFINITION_STATE') {
        show = VIEW_TYPE.EDIT;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.whichView() === 'DEFINITION') {
          show = VIEW_TYPE.EDIT;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (
          this.whichView() === 'DEFINITION' &&
          [0, 1].includes(this.objective.DefinitionState)
        ) {
          show = VIEW_TYPE.EDIT;
        } else if (
          this.objective.Visibility === 'Private' &&
          [2].includes(this.objective.DefinitionState)
        ) {
          show = this.whichView().startsWith('DEFINITION')
            ? VIEW_TYPE.EDIT
            : VIEW_TYPE.VIEW_ONLY;
        }
      }
    }

    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showVisibility() {
    let show: VIEW_TYPE = VIEW_TYPE.VIEW_ONLY;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.currentState === 'DEFINITION_STATE') {
        show = VIEW_TYPE.EDIT;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.whichView() === 'DEFINITION') {
          show = VIEW_TYPE.EDIT;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (
          this.whichView() === 'DEFINITION' &&
          [0, 1].includes(this.objective.DefinitionState)
        ) {
          show = VIEW_TYPE.EDIT;
        } else if (
          this.objective.Visibility === 'Private' &&
          [2].includes(this.objective.DefinitionState)
        ) {
          show = this.whichView().startsWith('DEFINITION')
            ? VIEW_TYPE.EDIT
            : VIEW_TYPE.VIEW_ONLY;
        }
      }
    }

    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showDueDate() {
    let show: VIEW_TYPE = VIEW_TYPE.VIEW_ONLY;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.currentState === 'DEFINITION_STATE') {
        show = VIEW_TYPE.EDIT;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.whichView() === 'DEFINITION') {
          show = VIEW_TYPE.EDIT;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = VIEW_TYPE.EDIT;
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (
          this.whichView() === 'DEFINITION' &&
          [0, 1].includes(this.objective.DefinitionState)
        ) {
          show = VIEW_TYPE.EDIT;
        } else if (
          this.objective.Visibility === 'Private' &&
          [2].includes(this.objective.DefinitionState)
        ) {
          show =
            this.whichView() === 'DEFINITION'
              ? VIEW_TYPE.EDIT
              : VIEW_TYPE.VIEW_ONLY;
        }
      }
    }

    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showSelfEvaluation() {
    let show: VIEW_TYPE = VIEW_TYPE.HIDDEN;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (
        this.referralAction === REFERRAL_ACTION.VIEW &&
        this.currentState === 'EVALUATION_STATE'
      ) {
        show = VIEW_TYPE.VIEW_ONLY;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (
        this.referralAction === REFERRAL_ACTION.VIEW &&
        this.currentState === 'EVALUATION_STATE'
      ) {
        show = VIEW_TYPE.VIEW_ONLY;
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (
          this.beforeEvaluationEnd &&
          this.currentState === 'EVALUATION_STATE'
        ) {
          show = VIEW_TYPE.EDIT;
        } else if (this.currentState === 'EVALUATION_STATE') {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      }
    }

    return show;
  }

  /**
   * output
   * VIEW_ONLY
   * HIDDEN
   * EDIT
   */
  showBossEvaluation() {
    let show: VIEW_TYPE = VIEW_TYPE.HIDDEN;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (
        this.referralAction === REFERRAL_ACTION.VIEW &&
        this.currentState === 'EVALUATION_STATE'
      ) {
        show = VIEW_TYPE.VIEW_ONLY;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (
        this.referralAction === REFERRAL_ACTION.VIEW &&
        this.currentState === 'EVALUATION_STATE'
      ) {
        show = VIEW_TYPE.EDIT;
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState === 'EVALUATION_STATE') {
          show = VIEW_TYPE.VIEW_ONLY;
        }
      }
    }

    return show;
  }

  /** CANCEL */
  showCancelButton() {
    let show: boolean = false;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = true;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = true;
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = true;
      }
    }
    return show;
  }

  /**
   * Condizione per cui lo sheet è cancellabile
   */
  isDeletable() {
    let show: boolean = false;

    if (this.referralAction === REFERRAL_ACTION.VIEW) {
      //Visible only if action is 'View' and not visible in other cases: i.e.: "Assign"
      switch (this.ViewMode) {
        case VIEW_MODE.ADMIN:
          //Allways visible for Admin
          show = this.inDefinitionPhase && !this.showRevoke();
          break;
        case VIEW_MODE.MANAGER:
          show = this.ImAuthor && this.inDefinitionPhase && !this.showRevoke();
        case VIEW_MODE.INDIVIDUAL:
          //Visible for Manager and Individual only if the objective belongs to him
          show =
            this.objective.Visibility == 'Private' ||
            (this.inDefinitionPhase && this.ImAuthor);
          break;
      }
    }

    return show && !this.showRevoke();
  }

  /** REQUEST APPROVAL */

  isApprovalRequestVisible() {
    let show: boolean = false;
    if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show =
          this.objective &&
          this.objective.Visibility != 'Private' &&
          this.cyclesUtilitiesService.inDefinitionPhase(
            this.currentCycle,
            VIEW_MODE.INDIVIDUAL
          );
      } else if (this.referralAction === REFERRAL_ACTION.VIEW) {
        show =
          this.objective &&
          this.objective.Visibility != 'Private' &&
          [0].includes(this.objective.DefinitionState) &&
          this.cyclesUtilitiesService.inDefinitionPhase(
            this.currentCycle,
            VIEW_MODE.INDIVIDUAL
          );
      }
    }

    return show;
  }

  checkDisabledApprovalBtn() {
    let disabled = true;
    if (!this.objective) return true;
    disabled = !this.objective.Name;
    //console.log(disabled, this.objective);
    return disabled;
  }

  /** ASSIGN */

  isAssignVisible() {
    let show: boolean = false;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = true;
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.ASSIGN) {
        show = true;
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
    }
    return show;
  }

  checkDisabledAssignBtn() {
    let disabled = true;
    if (!this.objective) return true;
    disabled = !this.objective.Name || this.objective.Employees.length === 0;
    return disabled;
  }

  /** APPROVE */

  isApproveVisible() {
    let show: boolean = false;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        show =
          this.objective.ApprovalStatus === 'ApprovalRequested' &&
          this.currentState === 'DEFINITION_STATE';
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        show =
          this.currentState === 'DEFINITION_STATE' &&
          this.entroAgreeDate &&
          this.objective.ApprovalStatus === 'ApprovalRequested';
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
    }

    return show;

    /*
    return this.objective.Id &&
      this.ViewMode !== VIEW_MODE.INDIVIDUAL &&
      this.entroAgreeDate &&
      this.objective.ApprovalStatus === 'ApprovalRequested';
    */
  }

  /** UPDATE */

  isUpdateVisible() {
    let show: boolean = false;

    if (this.ViewMode === VIEW_MODE.ADMIN) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.currentState === 'DEFINITION_STATE') {
          if ([0, 2].includes(this.objective.DefinitionState)) {
            show = true;
          }
        } else {
          show = true;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        if (this.whichView() === 'DEFINITION') {
          // SE Obiettivo Open/Aperto o Approvato/Set
          if ([0, 2].includes(this.objective.DefinitionState)) {
            show = true;
          }
        } else {
          show = true;
        }
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      /*
      if (this.referralAction === REFERRAL_ACTION.VIEW) {
        show = this.objective &&
          this.objective.Visibility != 'Private' &&
          [1, 2].includes(this.objective.DefinitionState) &&
          this.cyclesUtilitiesService.inDefinitionPhase(this.currentCycle, VIEW_MODE.INDIVIDUAL);
      }
      */
    }

    return show && !this.afterEvaluation;
    //return this.objective.Id && this.ViewMode !== VIEW_MODE.INDIVIDUAL
  }

  /** */
  onSelectTab(event) {
    // $('tab#KeyResults').css('height', `${$('tab#About').height()}px`);
    // console.log(`${$('tab#About').height()}px`);
  }

  //
  addNewKR() {
    this.enableAddChecklistItem = true;
    setTimeout(() => {
      $('#newCheckItem').focus();
    }, 100);
  }

  onChangeKR(kr: any) {
    console.log('onChangeKR', kr);
    let sum = 0;
    this.objective.CheckList.forEach((item: any) => {
      const max = item.TargetValue - item.MinValue;
      const value = item.Value - item.MinValue;
      //sum += item.Value;
      sum += (value * 100) / max;
      console.log(item, (value * 100) / max);
    });
    if (
      this.objective.ObjectiveProgressPercentage !=
      sum / this.objective.CheckList.length
    ) {
      this.objective.ObjectiveProgressPercentage =
        sum / this.objective.CheckList.length;
      if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
        this.srv.SaveOKRProgressValue(kr).subscribe((result: any) => {
          console.log('SaveOKRProgressValue', result);
        });
      }
    }
  }

  onChangeObjectiveKR(obj: any) {
    if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      this.srv
        .SaveObjectProgressValue(obj.Id, obj.ObjectiveProgressPercentage)
        .subscribe((result: any) => {
          console.log('SaveObjectProgressValue', result);
        });
    }
  }

  editKRValue(kr: any, index: number) {
    kr.isInputEditing = true;
    setTimeout(() => {
      $(`#input_kr_${index}`).focus();
      $(`#input_kr_${index}`).select();
    }, 250);
  }

  editKRObjectiveValue() {
    if (
      this.whatCanIdo() !== 'DRAG' &&
      this.objective.CheckList &&
      this.objective.CheckList.length > 0
    )
      return;
    this.objective.isInputEditing = true;
    setTimeout(() => {
      $(`#input_kr_objective`).focus();
      $(`#input_kr_objective`).select();
    }, 250);
  }

  editKRLabel(kr: any) {
    kr.isEditing = !kr.isEditing;
    kr.showOkrSettings = true;
    if (kr.isEditing) {
      setTimeout(() => {
        $('#newKRItem').focus();
        $('#newKRItem').select();
      }, 250);
    }
  }

  canAddKR() {
    const whichView = this.whichView();
    // if(this.isManager) {
    //   whichView = "DEFINITION";
    // }
    if (!whichView) return false;
    // Se sono admin e sono in definizione (anche sola lettura) oppure sono in definizione oppure l'obbiettivo è nuovo
    // let result = (this.ViewMode === VIEW_MODE.ADMIN && whichView.startsWith("DEFINITION") || !this.objective.Id || whichView.startsWith("DEFINITION")) &&
    //   this.beforeEvaluationEnd && // e sono entro la fine data valutazione
    //   ((this.ViewMode === VIEW_MODE.INDIVIDUAL && this.objective.ApprovalStatus !== 'Approved' && whichView.startsWith("DEFINITION")) || !whichView.startsWith("DEFINITION") || this.objective.Visibility === 'Private' || (this.ViewMode === VIEW_MODE.ADMIN && whichView.startsWith("DEFINITION"))) && // Se l'obbiettivo si trova nella colonna approvato
    //   !this.objective.Suspended && !this.isPostponedSource && // Se l'obbiettivo non è revocato o postposto
    //   !this.enableAddChecklistItem; //e cmq non c'è enableAddChecklistItem

    let result = false;
    if (this.ViewMode === VIEW_MODE.ADMIN) {
      // Se sono ADMIN e sono in definizione OPPURE l'obbiettivo è nuovo MA CMQ prima della fine ciclo
      result =
        (whichView.startsWith('DEFINITION') || !this.objective.Id) &&
        this.beforeEvaluationEnd;
    } else if (this.ViewMode === VIEW_MODE.MANAGER) {
      //Se sono MANAGER e sono in definizione OPPURE l'obbiettivo è nuovo MA CMQ SEMPRE ENTRO LA FASE DI DEFINIZIONE
      // result = (whichView.startsWith("DEFINITION") || !this.objective.Id) && !this.afterDefinitionPhase;
      if (this.objective.Id == 'direct') {
        result =
          (whichView.startsWith('DEFINITION') || this.objective.Id) &&
          !this.afterDefinitionPhase;
      } else {
        result =
          (whichView.startsWith('DEFINITION') || !this.objective.Id) &&
          !this.afterDefinitionPhase;
      }
    } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      // Se sono INDIVIDUO e sono in fase definizione e l'obbiettivo non è approvato OPPURE la visibilità è privata
      result =
        this.currentState === 'DEFINITION_STATE' &&
        (this.objective.ApprovalStatus !== 'Approved' ||
          this.objective.Visibility === 'Private');
    }

    return (
      result &&
      !this.objective.Suspended && // e non deve essere sospeso
      !this.isPostponedSource && // o postposto
      !this.enableAddChecklistItem
    ); // e non ci deve essere il pulsante Aggiungi
  }

  whatCanIdoObjKR() {
    let result: string = 'DISABLED';
    const wcid = this.whatCanIdo();
    const hasChildren =
      this.objective.CheckList && this.objective.CheckList.length > 0;

    if (wcid === 'DRAG') {
      return !hasChildren ? 'DRAG' : 'DISABLED';
    } else {
      return wcid;
    }

    // (whichView() === 'EXECUTION' || (inExecutionPhase && currentState === 'EXECUTION_STATE' && ViewMode === viewMode.INDIVIDUAL && objective.Id)) && (!objective.CheckList || objective.CheckList.length == 0) && ViewMode !== viewMode.MY_STORY
  }

  /**
   *
   */
  whatCanIdo() {
    let result: string = 'DISABLED';

    const whichView = this.whichView();

    if (
      this.ViewMode == VIEW_MODE.MY_STORY || // Se arrivo da mystory
      (this.ViewMode !== VIEW_MODE.ADMIN && whichView === 'EVALUATION') || // Se sono in evaluation e NON sono admin
      (this.objective.ApprovalStatus === 'Approved' &&
        whichView === 'DEFINITION' &&
        this.objective.Visibility !== 'Private') || // Se l'obbiettivo si trova nella colonna approvato
      !this.beforeEvaluationEnd || // se il ciclo è scaduto, data oltre la valutazione
      (this.objective.Suspended && this.ViewMode === VIEW_MODE.INDIVIDUAL) || // se il ciclo è revocato e sono individual
      (this.isPostponedSource && this.ViewMode === VIEW_MODE.INDIVIDUAL)
    ) {
      // se il ciclo è postposto e sono individual

      result = 'DISABLED';
    } else if (whichView === 'EXECUTION') {
      result = 'DRAG';
    } else if (whichView === 'DEFINITION') {
      result = 'EDIT';
    } else if (
      this.ViewMode == VIEW_MODE.INDIVIDUAL &&
      this.objective.Visibility === 'Private' &&
      this.currentState === 'EXECUTION_STATE'
    ) {
      result = 'DRAG';
    }

    return result;
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    return Number(value).toLocaleString(this.translateService.currentLang, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }

  getOKRTooltip(checkItem) {
    let s = this.coreService.getTranslation('OBJECTIVE_SHEET_POPUP.OKR.Start');
    let t = this.coreService.getTranslation('OBJECTIVE_SHEET_POPUP.OKR.Target');

    const minVal =
      checkItem.MeasureType != 1
        ? this.formatNumber(checkItem.MinValue, 0)
        : this.formatNumber(checkItem.MinValue, 2);
    const maxVal =
      checkItem.MeasureType != 1
        ? this.formatNumber(checkItem.TargetValue, 0)
        : this.formatNumber(checkItem.TargetValue, 2);

    return s + ': ' + minVal + '|' + t + ': ' + maxVal;
  }

  /***************************************************************************
   ____  _  _______             _   _      _                      _
  / __ \| |/ /  __ \           | \ | |    | |                    | |
 | |  | | ' /| |__) |  ______  |  \| | ___| |___      _____  _ __| | _____
 | |  | |  < |  _  /  |______| | . ` |/ _ \ __\ \ /\ / / _ \| '__| |/ / __|
 | |__| | . \| | \ \           | |\  |  __/ |_ \ V  V / (_) | |  |   <\__ \
  \____/|_|\_\_|  \_\          |_| \_|\___|\__| \_/\_/ \___/|_|  |_|\_\___/

  ***************************************************************************/

  canShowSelectTargetLinks() {
    // let result: boolean = false;
    // const whichView = this.whichView();

    // if (this.ViewMode === VIEW_MODE.ADMIN) {
    //   result = whichView.startsWith("DEFINITION") && this.beforeEvaluationEnd;

    // } else if (this.ViewMode === VIEW_MODE.MANAGER) {
    //   result = this.inDefinitionPhase || this.inExecutionPhase;

    // } else if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
    //   result = this.inDefinitionPhase || this.inExecutionPhase;

    // }

    // return result;
    let canDo: boolean = this.beforeEvaluationEnd;
    if (canDo) {
      if (this.ViewMode == VIEW_MODE.MY_STORY) {
        canDo = false; // this.userInfo.linkedEmployeeId==this.objective.Author.Id;
      } else {
      }
    }

    return canDo;
  }

  canUnlink(item: any, type: string) {
    if (this.ViewMode == VIEW_MODE.MY_STORY) return false;

    if (type === 'UpLink') {
      return (
        this.ViewMode === VIEW_MODE.ADMIN ||
        this.ViewMode === VIEW_MODE.MANAGER ||
        this.ImOwner
      );
    } else if (type === 'DownLink') {
      return false; // this.ImOwner || (this.ViewMode === VIEW_MODE.ADMIN && this.beforeEvaluationEnd);
    } else {
      return false;
    }
  }

  selectedTargetLinks: any;
  onSelect_a2mTargetLinks(event: any) {
    this.selectedTargetLinks = [];
    // this.linkTarget(event);
  }

  onClose_a2mTargetLinks(event: any) {
    $('angular2-multiselect.availableLinksDropDown .selected-list').show();

    // Forzo l'update della lista opzioni
    const updateList = JSON.parse(JSON.stringify(this.listUpLinks));
    this.listUpLinks = [];
    setTimeout(() => {
      this.listUpLinks = updateList;
    }, 1000);
  }

  onOpen_a2mTargetLinks(event: any) {
    $('angular2-multiselect.availableLinksDropDown .selected-list').hide();
    setTimeout(() => {
      $('angular2-multiselect.availableLinksDropDown input').focus();
      $('angular2-multiselect.availableLinksDropDown input').select();
    }, 1000);
  }
  //---------------------------------  link TARGET --------------------------------------------------------------------------------
  isLinking: boolean = false;
  linkTarget(item: any, forceId: boolean = false) {
    if (this.isLinking) return;
    this.isLinking = true;
    const targetId = forceId ? item.Id : item.Type == 0 ? item.OKRId : item.Id;
    if (this.ViewMode === VIEW_MODE.INDIVIDUAL) {
      if (!this.objective.Id) {
        // Save object and get the objective.Id
        this.saveTheObjective().subscribe((result: any) => {
          this.objective.Id = result.Id;
          this.updateObjectiveInSheets.emit(this.objective);
          this.originalObjective = JSON.parse(JSON.stringify(this.objective));
          this.isLinking = false;
          this.linkTarget(item, forceId);
        });
      } else {
        if (!item.IsLinked) {
          item.IsLinked = true;
          if (!this.objective.UpLinks) this.objective.UpLinks = [];

          this.objective.UpLinks.push({
            Id: item.ObjectiveId,
            ObjectiveName: item.ObjectiveName,
            LinkedKRName: item.OKRName,
            OKRId: item.OKRId,
            Employee: {
              Id: item.Employee.Id,
              Name: item.Employee.Name,
              Picture: item.Employee.Picture,
            },
            Type: item.Type,
            MinValue: item.Min,
            TargetValue: item.Max,
            MeasureName: item.MeasureName,
            Value: 0,
            MeasureType: item.MeasureType,
            IsLinked: true,
          });
        } else {
          item.IsLinked = false;
          if (item.Type === 0) {
            this.objective.UpLinks = this.objective.UpLinks.filter(
              (x) => x.OKRId != item.OKRId
            );
            this.objective.DownLinks = this.objective.DownLinks.filter(
              (x) => x.OKRId != item.OKRId
            );
          } else {
            this.objective.UpLinks = this.objective.UpLinks.filter(
              (x) =>
                x.Id != item.Id ||
                x.OKRId !== '00000000-0000-0000-0000-000000000000'
            );
            this.objective.DownLinks = this.objective.DownLinks.filter(
              (x) =>
                x.Id != item.Id ||
                x.OKRId !== '00000000-0000-0000-0000-000000000000'
            );
          }

          // var it=this.listUpLinks.find(x=>x.Id===item.Id); => http://tools.avaservice.eu/mantisbt/view.php?id=1001
          const index = this.listUpLinks.findIndex((x) =>
            item.Type == 1
              ? x.ObjectiveId === item.Id &&
                x.OKRId == '00000000-0000-0000-0000-000000000000'
              : x.OKRId == item.OKRId
          );
          if (index >= 0) {
            this.listUpLinks[index].IsLinked = false;
            console.log('index', index);
          }

          const updateList = JSON.parse(JSON.stringify(this.listUpLinks));
          this.listUpLinks = [];
          setTimeout(() => {
            this.listUpLinks = updateList;
          }, 1000);
        }

        this.companyReportService
          .linkTarget(this.objective.Id, targetId, item.Type == 1)
          .subscribe(
            (result: any) => {
              // this.translate('refresh');
              if (this.objective.UpLinks) this.translateUplink('refresh');
              if (this.objective.DownLinks) this.translateDownlink('refresh');
              // this.listUpLinks=JSON.parse(JSON.stringify(this.listUpLinks));
              // if (!forceId) {
              //   this.getUpLinks(this.currentUpLinksPageStart, this.upLinksPageSize, this.currentUpLinksFilterText, true);
              //   this.ref.detectChanges();
              // }

              const message =
                result.Message === 'Linked'
                  ? this.coreService.getTranslation(
                      'OBJECTIVE_SHEET_POPUP.NetworksTab.SuccessLinked'
                    )
                  : this.coreService.getTranslation(
                      'OBJECTIVE_SHEET_POPUP.NetworksTab.SuccessUnlinked'
                    );
              this.coreService.toasterMessage('success', '', message);
              this.isLinking = false;
              // this.cycleManagerService.getObjectiveById(this.objective.Id, 'boh').subscribe(
              //   (objectiveResult: any) => {
              //     this.objective.UpLinks = JSON.parse(JSON.stringify(objectiveResult.UpLinks));
              //     this.objective.DownLinks = JSON.parse(JSON.stringify(objectiveResult.DownLinks));
              //     this.objective.Followers = JSON.parse(JSON.stringify(objectiveResult.Followers));

              //   }, (error: any) => { this.isLinking = false; }
              // );
            },
            (error: any) => {
              let foundItem = this.listUpLinks.find((x) => x.Id === item.Id);
              if (foundItem) foundItem.IsLinked = false;
              this.isLinking = false;
            }
          );
        //IMMEDIATELY FLAGS THE ITEM
        this.ref.detectChanges();
        //  let foundItem= this.listUpLinks.find(x=>x.Id===item.Id);
        //  if(foundItem) foundItem.IsLinked =  !foundItem.IsLinked;
      }
    } else {
      //IN CASE OFF ASSIGNMENT ADD LOCAL LIST OF LINKS
      if (!this.objective.UpLinks) {
        this.objective.UpLinks = [];
      }
      if (!item.IsLinked) {
        item.IsLinked = true;
        if (!this.objective.UpLinks) this.objective.UpLinks = [];
        this.objective.UpLinks.push({
          Id: item.ObjectiveId,
          ObjectiveName: item.ObjectiveName,
          LinkedKRName: item.OKRName,
          OKRId: item.OKRId,
          Employee: {
            Id: item.Employee.Id,
            Name: item.Employee.Name,
            Picture: item.Employee.Picture,
          },
          Type: item.Type,
          MinValue: item.Min,
          TargetValue: item.Max,
          MeasureName: item.MeasureName,
          Value: 0,
          MeasureType: item.MeasureType,
          IsLinked: true,
        });
      } else {
        item.IsLinked = false;
        if (item.Type === 0) {
          this.objective.UpLinks = this.objective.UpLinks.filter(
            (x) => x.OKRId != item.OKRId
          );
          this.objective.DownLinks = this.objective.DownLinks.filter(
            (x) => x.OKRId != item.OKRId
          );
        } else {
          this.objective.UpLinks = this.objective.UpLinks.filter(
            (x) =>
              x.Id != item.Id ||
              x.OKRId !== '00000000-0000-0000-0000-000000000000'
          );
          this.objective.DownLinks = this.objective.DownLinks.filter(
            (x) =>
              x.Id != item.Id ||
              x.OKRId !== '00000000-0000-0000-0000-000000000000'
          );
        }

        // var it=this.listUpLinks.find(x=>x.Id===item.Id); => http://tools.avaservice.eu/mantisbt/view.php?id=1001
        const index = this.listUpLinks.findIndex((x) =>
          item.Type == 1
            ? x.ObjectiveId === item.Id &&
              x.OKRId == '00000000-0000-0000-0000-000000000000'
            : x.OKRId == item.OKRId
        );
        if (index >= 0) {
          this.listUpLinks[index].IsLinked = false;
          console.log('index', index);
        }

        const updateList = JSON.parse(JSON.stringify(this.listUpLinks));
        this.listUpLinks = [];
        setTimeout(() => {
          this.listUpLinks = updateList;
        }, 1000);
      }

      const message = item.IsLinked
        ? this.coreService.getTranslation(
            'OBJECTIVE_SHEET_POPUP.NetworksTab.SuccessLinked'
          )
        : this.coreService.getTranslation(
            'OBJECTIVE_SHEET_POPUP.NetworksTab.SuccessUnlinked'
          );
      this.coreService.toasterMessage('success', '', message);

      this.isLinking = false;
      this.ref.detectChanges();
    }
  }

  fetchMoreUpLinks(event: any) {
    if (
      event.endIndex === this.listUpLinks.length - 1 && // Se sono alla fine delle righe caricate
      this.listUpLinks.length > 0 &&
      this.listUpLinks.length - 1 < this.totalRowsUplink && // se ho dei risultati e non ho superato la fine
      !this.a2mSettingsNetworksLoading
    ) {
      // Se non sto già caricando dei risultati

      // console.log(event);
      this.currentUpLinksPageStart++;
      if (this.currentUpLinksPageStart < this.totalPages) {
        this.getUpLinks(
          this.currentUpLinksPageStart,
          this.upLinksPageSize,
          this.currentUpLinksFilterText
        );
      }
    }
  }

  listUpLinks: any = [];
  currentUpLinksPageStart: number = 1;
  upLinksPageSize: number = 20;
  totalRowsUplink: number = 0;
  currentUpLinksFilterText: string = '';
  totalPages: number = 0;
  previuosCallFilter = {
    pageStart: 1,
    pageSize: 20,
    filter: null,
  };
  getUpLinks(
    pageStart: number = 1,
    pageSize: number = 20,
    filter: string = '',
    refresh: boolean = false
  ) {
    this.a2mSettingsNetworksLoading = true;
    if (
      this.previuosCallFilter.filter != filter ||
      this.previuosCallFilter.pageStart !== pageStart ||
      this.previuosCallFilter.pageSize !== pageSize
    ) {
      this.previuosCallFilter.filter = filter;
      this.previuosCallFilter.pageStart = pageStart;
      this.previuosCallFilter.pageSize = pageSize;
      let cId = this.currentCycle.Id || this.currentCycle.CycleId;
      this.companyReportService
        .getUpLinks(this.objective.Id, cId, pageStart, pageSize, filter)
        .subscribe(
          (availableLinkTargets: any) => {
            if (refresh) this.listUpLinks = [];
            this.totalPages =
              availableLinkTargets.TotalRows / availableLinkTargets.Size;
            this.listUpLinks = this.listUpLinks.concat(
              availableLinkTargets.Rows
              // //http://tools.avaservice.eu/mantisbt/view.php?id=1001
              // .map((row) => {
              //   row.itemName = row.Name;
              //   row.id = row.Id;
              //   return row;
              // })
            );
            this.a2mSettingsNetworksLoading = false;
            this.translate('uplinks');
            this.totalRowsUplink = availableLinkTargets.TotalRows;
          },
          () => {
            this.a2mSettingsNetworksLoading = false;
          }
        );
    }
  }

  onSearchUpLinks(event) {
    const filter = event.target.value;
    this.upLinksModelChanged.next(filter);
  }

  showObjectiveSheet(objectiveId: string) {
    this.cycleManagerService
      .getObjectiveById(objectiveId, '')
      .subscribe((objectiveResult: any) => {
        const content: any = {
          objective: objectiveResult,
          state: this.currentState,
          cycle: this.currentCycle,
          ViewMode: this.ViewMode,
          referral: this.referral,
          referralAction: this.referralAction,
        };

        const modal = this.modalService.show(ObjSheetPopupComponent, {
          class: 'objective-sheet-popup',
        });
        (<ObjSheetPopupComponent>modal.content).showModal(content);

        if ($('.modal-dialog.objective-sheet-popup').length % 2 === 0) {
          $('.modal:nth-child(n) .modal-dialog.objective-sheet-popup')
            .removeClass('objective-sheet-popup-left')
            .removeClass('objective-sheet-popup-right');
          $(
            '.modal:nth-child(2n-1) .modal-dialog.objective-sheet-popup'
          ).addClass('objective-sheet-popup-left');
          $(
            '.modal:nth-child(2n) .modal-dialog.objective-sheet-popup'
          ).addClass('objective-sheet-popup-right');
        } else if ($('.modal-dialog.objective-sheet-popup').length % 2 !== 0) {
          $('.modal:nth-child(n) .modal-dialog.objective-sheet-popup')
            .removeClass('objective-sheet-popup-left')
            .removeClass('objective-sheet-popup-right');
          $(
            '.modal:nth-child(2n-1) .modal-dialog.objective-sheet-popup'
          ).addClass('objective-sheet-popup-left');
          $(
            '.modal:nth-child(2n) .modal-dialog.objective-sheet-popup'
          ).addClass('objective-sheet-popup-right');
          $('.modal:last-child .modal-dialog.objective-sheet-popup')
            .removeClass('objective-sheet-popup-left')
            .removeClass('objective-sheet-popup-right');
        }
      });

    this.subscriptionOnHidden = this.modalService.onHidden.subscribe(() => {
      if ($('.modal-dialog.objective-sheet-popup').length === 1) {
        $('.modal:nth-child(n) .modal-dialog.objective-sheet-popup')
          .removeClass('objective-sheet-popup-left')
          .removeClass('objective-sheet-popup-right');
      }
      if ($('.modal-dialog.objective-sheet-popup').length % 2 === 0) {
        $('.modal:nth-child(n) .modal-dialog.objective-sheet-popup')
          .removeClass('objective-sheet-popup-left')
          .removeClass('objective-sheet-popup-right');
        $(
          '.modal:nth-child(2n-1) .modal-dialog.objective-sheet-popup'
        ).addClass('objective-sheet-popup-left');
        $('.modal:nth-child(2n) .modal-dialog.objective-sheet-popup').addClass(
          'objective-sheet-popup-right'
        );
      } else if ($('.modal-dialog.objective-sheet-popup').length % 2 !== 0) {
        $('.modal:nth-child(n) .modal-dialog.objective-sheet-popup')
          .removeClass('objective-sheet-popup-left')
          .removeClass('objective-sheet-popup-right');
        $(
          '.modal:nth-child(2n-1) .modal-dialog.objective-sheet-popup'
        ).addClass('objective-sheet-popup-left');
        $('.modal:nth-child(2n) .modal-dialog.objective-sheet-popup').addClass(
          'objective-sheet-popup-right'
        );
        $('.modal:last-child .modal-dialog.objective-sheet-popup')
          .removeClass('objective-sheet-popup-left')
          .removeClass('objective-sheet-popup-right');
      }
    });
  }

  checkMinMax(ckItem: any) {
    if (
      (this.ViewMode !== VIEW_MODE.INDIVIDUAL ||
        (this.ViewMode === VIEW_MODE.INDIVIDUAL &&
          this.objective.Visibility === 'Private')) &&
      ckItem.Value > 0
    ) {
      this.minmaxChange.next(ckItem);
    }
  }

  translateApi(text, Tname) {
    this.pp360SpinnerService.showSpinner(true);
    let selectedLang = this.userInfo.language;
    if (this.objective.Tname) {
      this.pp360SpinnerService.showSpinner(false);
    } else {
      if (text) {
        this.coreService
          .translateText(text, null, selectedLang)
          .subscribe((result: any) => {
            this.objective[Tname] = result.TranslatedText;
            this.pp360SpinnerService.showSpinner(false);
          });
      }
    }
  }

  multiTranslateApi(oldName, Tname, KeyName) {
    this.pp360SpinnerService.showSpinner(true);
    let selectedLang = this.userInfo.language;
    let list = this.objective[KeyName];
    list.forEach((element) => {
      if (element.Tname) {
        this.pp360SpinnerService.showSpinner(false);
      } else {
        if (element[oldName]) {
          this.coreService
            .translateText(element[oldName], null, selectedLang)
            .subscribe((result: any) => {
              element[Tname] = result.TranslatedText;
              if (KeyName == 'Comments') {
                element['showTranslate'] = false;
              }
              this.pp360SpinnerService.showSpinner(false);
            });
        }
      }
    });
  }

  translate(data) {
    if (data === 'uplinks') {
      this.pp360SpinnerService.showSpinner(true);
      let selectedLang = this.userInfo.language;
      this.listUpLinks.forEach((element) => {
        if (element.ObjectiveName) {
          this.coreService
            .translateText(element.ObjectiveName, null, selectedLang)
            .subscribe((result: any) => {
              element.ObjectiveNameText = result.TranslatedText;
              this.pp360SpinnerService.showSpinner(false);
            });
        }
        if (element.OKRName) {
          this.coreService
            .translateText(element.OKRName, null, selectedLang)
            .subscribe((result: any) => {
              element.OKRNameText = result.TranslatedText;
              this.pp360SpinnerService.showSpinner(false);
            });
        }
      });
    } else {
      // if (data !== 'refresh') {
      // } else {
      this.showTranslate = !this.showTranslate;
      if (this.objective.Name)
        this.translateApi(this.objective.Name, 'TranslatedName');
      if (this.objective.Description)
        this.translateApi(this.objective.Description, 'TranslatedDescription');
      if (this.objective.CheckList) {
        this.multiTranslateApi('Text', 'TranslatedText', 'CheckList');
        this.multiTranslateApi('MeasureName', 'MeasureNameText', 'CheckList');
      }
      if (this.objective.UpLinks) {
        this.multiTranslateApi(
          'ObjectiveName',
          'TranslatedObjectiveName',
          'UpLinks'
        );
        this.multiTranslateApi('MeasureName', 'MeasureNameText', 'UpLinks');
        this.multiTranslateApi('LinkedKRName', 'LinkedKRNameText', 'UpLinks');
      }
      if (this.objective.DownLinks) {
        this.multiTranslateApi(
          'ObjectiveName',
          'TranslatedObjectiveName',
          'DownLinks'
        );
        this.multiTranslateApi('MeasureName', 'MeasureNameText', 'DownLinks');
        this.multiTranslateApi('LinkedKRName', 'LinkedKRNameText', 'DownLinks');
      }
      if (this.objective.Comments) {
        this.multiTranslateApi('Text', 'TranslatedText', 'Comments');
      }
      // this.showTranslate = true;
    }
    // }
  }

  translateUplink(data) {
    if (this.objective.UpLinks?.TranslatedObjectiveName && data !== 'refresh') {
      this.showTranslate = !this.showTranslate;
    } else {
      this.multiTranslateApi(
        'ObjectiveName',
        'TranslatedObjectiveName',
        'UpLinks'
      );
      this.multiTranslateApi('MeasureName', 'MeasureNameText', 'UpLinks');
      this.multiTranslateApi('LinkedKRName', 'LinkedKRNameText', 'UpLinks');
    }
  }

  translateDownlink(data) {
    if (
      this.objective.DownLinks?.TranslatedObjectiveName &&
      data !== 'refresh'
    ) {
      this.showTranslate = !this.showTranslate;
    } else {
      this.multiTranslateApi(
        'ObjectiveName',
        'TranslatedObjectiveName',
        'DownLinks'
      );
      this.multiTranslateApi('MeasureName', 'MeasureNameText', 'DownLinks');
      this.multiTranslateApi('LinkedKRName', 'LinkedKRNameText', 'DownLinks');
    }
  }

  translatecheckList(data) {
    if (this.objective.CheckList?.TranslatedText && data !== 'refresh') {
      this.showTranslate = !this.showTranslate;
    } else {
      this.multiTranslateApi('Text', 'TranslatedText', 'CheckList');
      this.multiTranslateApi('MeasureName', 'MeasureNameText', 'CheckList');
    }
  }

  // allTranslate(data){
  //   this.translate(data);
  //   this.translatecheckList(data);
  //   this.translateUplink(data);
  //   this.translateDownlink(data);
  // }

  commentTranslate(index) {
    this.objective.Comments[index].showTranslate =
      !this.objective.Comments[index].showTranslate;
    if (this.objective.Comments[index].TranslatedText) {
    } else {
      this.multiTranslateApi('Text', 'TranslatedText', 'Comments');
    }
  }

  getObjectiveImage() {
    if (this.objective.Image) {
      return {
        'background-image': `url('${this.objective.Image}')`,
        'background-color': `var(--feed-background)`,
        border: `none`,
      };
    } else {
      return undefined;
    }
  }

  getImageSize(file) {
    return file ? (file.size / (1024 * 1024)).toFixed(2) : 0;
  }
  isDone() {
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(base64ToFile(this.croppedImage))
    );
    this.finalCroppedImage = url;
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  cropImage(file) {
    const cropModalRef = this.modalService.show(CustomImageCropperComponent, {
      class: 'image-change',
    });
    const content = {
      file,
      isRounded: false,
      aspectRatio: 4 / 2,
    };
    (<CustomImageCropperComponent>cropModalRef.content).showModal(content);
    (<CustomImageCropperComponent>cropModalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.croppedImage = result;
          this.uploadImage(file);
          this.isDone();
        }
      }
    );
  }

  onAddImage(event) {
    if (this.newPatData) {
      this.ischangeImg = true;
    }
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.isUploading = true;
      let file: File = fileList[0];
      this.currentFile = file;
      this.finalName = this.currentFile.name;
      this.cropImage(file);
    }
  }

  uploadImage(file) {
    const imageFile = base64ToFile(this.croppedImage);
    let formData: FormData = new FormData();
    formData.append('uploadFile', imageFile, file.name);
    this.pp360SpinnerService.showSpinner(true);
    this.sharedService.onAddImage(formData).subscribe(
      (data: any) => {
        this.optionalPhotoData = data;
        this.objective.Image =
          this.coreService.getBaseURL() + this.optionalPhotoData.ImageUrl;
        this.pp360SpinnerService.showSpinner(false);
        this.isUploading = false;
        this.doSaveObject('Image');
      },
      (err: any) => {
        console.log('onAddImage err => ', JSON.stringify(err));
        this.pp360SpinnerService.showSpinner(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
        this.isUploading = false;
      }
    );
  }

  selectImage() {
    const content: any = {
      Search: '',
    };
    let modal = this.modalService.show(ImageSelectorComponent, {
      class: 'image-selector',
    });
    (<ImageSelectorComponent>modal.content).showModal(content);

    // if (this.isMobile) {
    //   setTimeout(() => {
    //     $('.modal-backdrop').attr('style', 'z-index: 3002 !important');
    //     $('modal-container:last-child').css('z-index', '3003');
    //   }, 1000);
    // }
    if (this.newPatData) {
      this.ischangeImg = true;
    }
    (<ImageSelectorComponent>modal.content).onCloseModal.subscribe(
      (result: string) => {
        // console.log('onCloseModal', result);
        if (result) {
          // console.log('onCloseModal', result);
          this.loading$.next(true);
          this.objective.Image = result;

          setTimeout(() => {
            // Immagine fittizzia per sapere quando viene caricata
            var image = new Image();
            image.src = result;
            image.onload = () => {
              this.loading$.next(false);
            };
          }, 250);
        }
      }
    );

    (<ImageSelectorComponent>modal.content).onClose.subscribe((result: any) => {
      if (result) {
        this.finalCroppedImage = result.urls.small;
        // this.currentFile.size = 0;
        this.finalName = result.user.name;
        this.onEdit(result.urls.regular);

        this.objective.Image = result.urls.small;
        // console.log('Image', this.objective.Image);
        this.doSaveObject('Image');
        this.loading$.next(true);
        setTimeout(() => {
          // Immagine fittizzia per sapere quando viene caricata
          var image = new Image();
          image.src = this.objective.Image;
          image.onload = () => {
            this.loading$.next(false);
          };
        }, 250);
      }
    });

    modal.onHidden.subscribe(() => {
      $('.modal-backdrop').attr('style', 'z-index: 2040 !important');
    });
  }

  async onEdit(item: any) {
    const response = await fetch(item);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: blob.type });
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
    this.croppedImage = { file, url };
    this.cropImage(file);
  }

  onRemoveImg() {
    this.objective.Image = '';
    this.ischangeImg = true;
    const fileInput = document.getElementById('addImage') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.isEditableImg = false;
  }
  onImageShow() {
    setTimeout(() => {
      this.isEditableImg = false;
    }, 1000);
  }

  makeImgEditable() {
    if (this.isMobile) {
      this.isEditableImg = true;
    }
  }
  // onOutsideClick(event: any): void {
  //   const element = event.target.classList;
  //   if (element && element.length) {
  //     this.isSaveObjective = true;
  //     this.bsModalRef.hide();
  //   }
  // }
}
